@import url(https://fonts.googleapis.com/css2?family=Spartan:wght@500;

600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,500;0,700;1,500;1,700&family=Spartan:wght@500;600&display=swap);
.mce-floatpanel[role=alertdialog],
#mce-modal-block {
  display: none;
}

.coxswain-reset-tree {
  font-family: 'Inter', serif !important;
  --fore: black;
  --back: white;
  --highlight: #2699FB;
  --gray: rgb(85,85,85);
}

.coxswain-reset-tree *:not(.mce-ico) {
  font-family: 'Inter', serif !important;
}

.coxswain-reset-tree button {
  border: none;
}

.coxswain-reset-tree .invert {
  --fore: white;
  --back: black;
}

.coxswain-reset-tree .font-regular {
  font-weight: 400;
}

.coxswain-reset-tree .font-bold {
  font-weight: 700;
}

.coxswain-reset-tree .font-base {
  font-size: 14px;
}

.coxswain-reset-tree .font-sm {
  font-size: 10px;
}

.coxswain-reset-tree .font-lg {
  font-size: 38px;
}

.coxswain-reset-tree .tight {
  line-height: 1;
}

.coxswain-reset-tree .relaxes {
  line-height: 1.6;
}

.coxswain-reset-tree .text-fore {
  color: var(--fore);
}

.coxswain-reset-tree .bg-fore {
  background-color: var(--fore);
}

.coxswain-reset-tree .hover-text-fore:hover {
  color: var(--fore);
}

.coxswain-reset-tree .hover-bg-fore:hover {
  background-color: var(--fore);
}

.coxswain-reset-tree .text-back {
  color: var(--back);
}

.coxswain-reset-tree .bg-back {
  background-color: var(--back);
}

.coxswain-reset-tree .hover-text-back:hover {
  color: var(--back);
}

.coxswain-reset-tree .hover-bg-back:hover {
  background-color: var(--back);
}

.coxswain-reset-tree .text-highlight {
  color: var(--highlight);
}

.coxswain-reset-tree .bg-highlight {
  background-color: var(--highlight);
}

.coxswain-reset-tree .hover-text-highlight:hover {
  color: var(--highlight);
}

.coxswain-reset-tree .hover-bg-highlight:hover {
  background-color: var(--highlight);
}

.coxswain-reset-tree .text-gray {
  color: var(--gray);
}

.coxswain-reset-tree .bg-gray {
  background-color: var(--gray);
}

.coxswain-reset-tree .hover-text-gray:hover {
  color: var(--gray);
}

.coxswain-reset-tree .hover-bg-gray:hover {
  background-color: var(--gray);
}

.coxswain-reset-tree .block {
  display: block;
}

.coxswain-reset-tree .flex {
  display: flex;
}

.coxswain-reset-tree .inline-block {
  display: inline-block;
}

.coxswain-reset-tree .inline {
  display: inline;
}

.coxswain-reset-tree .border-sm {
  border: 1px solid var(--fore);
  border-radius: 3px;
}

.coxswain-reset-tree .border-sm-light {
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 3px;
}

.coxswain-reset-tree .border-lg {
  border: 3px solid var(--fore);
  border-radius: 3px;
}

.coxswain-reset-tree .border-lg-light {
  border: 3px solid rgba(0,0,0,0.1);
  border-radius: 3px;
}

.coxswain-reset-tree .p-left-sm {
  padding-left: 10px;
}

.coxswain-reset-tree .m-left-sm {
  margin-left: 10px;
}

.coxswain-reset-tree .p-right-sm {
  padding-right: 10px;
}

.coxswain-reset-tree .m-right-sm {
  margin-right: 10px;
}

.coxswain-reset-tree .p-top-sm {
  padding-top: 10px;
}

.coxswain-reset-tree .m-top-sm {
  margin-top: 10px;
}

.coxswain-reset-tree .p-bottom-sm {
  padding-bottom: 10px;
}

.coxswain-reset-tree .m-bottom-sm {
  margin-bottom: 10px;
}

.coxswain-reset-tree .p-sm {
  padding: 10px;
}

.coxswain-reset-tree .m-sm {
  margin: 10px;
}

.coxswain-reset-tree .p-left-md {
  padding-left: 20px;
}

.coxswain-reset-tree .m-left-md {
  margin-left: 20px;
}

.coxswain-reset-tree .p-right-md {
  padding-right: 20px;
}

.coxswain-reset-tree .m-right-md {
  margin-right: 20px;
}

.coxswain-reset-tree .p-top-md {
  padding-top: 20px;
}

.coxswain-reset-tree .m-top-md {
  margin-top: 20px;
}

.coxswain-reset-tree .p-bottom-md {
  padding-bottom: 20px;
}

.coxswain-reset-tree .m-bottom-md {
  margin-bottom: 20px;
}

.coxswain-reset-tree .p-md {
  padding: 20px;
}

.coxswain-reset-tree .m-md {
  margin: 20px;
}

.coxswain-reset-tree .z-base {
  z-index: 10000;
}

.coxswain-reset-tree .z-middle {
  z-index: 10001;
}

.coxswain-reset-tree .z-top {
  z-index: 10002;
}

.coxswain-reset-tree .flex-column-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.coxswain-reset-tree .flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.coxswain-reset-tree .flex-align-start {
  align-items: flex-start;
}

.coxswain-reset-tree .flex-column-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.coxswain-reset-tree .flex-row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.coxswain-reset-tree .flex-align-end {
  align-items: flex-end;
}

.coxswain-reset-tree .flex-column-space {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.coxswain-reset-tree .flex-row-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.coxswain-reset-tree .flex-align-space {
  align-items: space-between;
}

.coxswain-reset-tree .flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.coxswain-reset-tree .flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.coxswain-reset-tree .flex-align-center {
  align-items: center;
}

.coxswain-reset-tree .text-upper {
  text-transform: uppercase;
}

.coxswain-reset-tree .box-shadow {
  box-shadow: 0 4px 18px 3px rgba(0,0,0,0.12),0 10px 14px 1px rgba(0,0,0,0.14),0 6px 6px -3px rgba(0,0,0,0.2);
}

.coxswain-reset-tree textarea {
  width: 70%;
  height: 20vh;
  display: block;
}

.coxswain-reset-tree em {
  font-style: italic;
}

.coxswain-reset-tree strong {
  font-weight: bold;
}

.coxswain-reset-tree code {
  background: lightyellow;
}

.coxswain-reset-tree .--coxswain-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.coxswain-reset-tree .account-actions {
  list-style-type: none;
}

.coxswain-reset-tree .--coxswain-resources {
  position: fixed;
  top: 54px;
  left: 0;
  right: 0;
  overflow-y: scroll;
  max-height: calc(100vh - 54px);
}

.coxswain-reset-tree .--coxswain-resources form {
  width: 100%;
}

.coxswain-reset-tree .--coxswain-resources form input#search {
  height: 30px;
}

.coxswain-reset-tree .--coxswain-resources .buttons-right a {
  padding: 15px 30px;
  text-align: center;
  margin-right: 10px;
}

.coxswain-reset-tree .--coxswain-resources .resource__item>div a {
  border-left: 1px solid rgba(0,0,0,0.1);
}

.coxswain-reset-tree .--coxswain-resources .resource__item>div:hover a {
  opacity: 1;
}

.coxswain-reset-tree .--coxswain-resources .resources--box {
  display: grid;
  grid-template-columns: repeat(auto-fit, calc((100% / 2) - 13px));
  grid-gap: 20px;
}

@media screen and (min-width: 768px) {
  .coxswain-reset-tree .--coxswain-resources .resources--box {
    grid-template-columns: repeat(auto-fit, calc((100% / 3) - 14px));
  }
}

@media screen and (min-width: 1000px) {
  .coxswain-reset-tree .--coxswain-resources .resources--box {
    grid-template-columns: repeat(auto-fit, calc((100% / 4) - 15px));
  }
}

.coxswain-reset-tree .--coxswain-resources .resources--box .resource__item {
  height: 100%;
}

.coxswain-reset-tree .--coxswain-resources .resources--box .resource__item>div {
  height: 100%;
}

.coxswain-reset-tree .--coxswain-resources .zones-open ul {
  list-style-type: none;
}

.coxswain-reset-tree .--coxswain-resources .zones-open li {
  padding: 5px 10px;
}

.coxswain-reset-tree .resource__item_inner:hover {
  border-color: black;
}

.coxswain-module-new {
  border: 2px solid black;
  padding: 35px 0 50px;
  height: 80vh;
  max-height: 80vh;
  position: fixed;
}

.coxswain-module-new .coxswain-module-container {
  height: 100%;
  width: 100%;
}

.coxswain-module-new .coxswain-module-container form {
  width: 100%;
}

.coxswain-module-new input {
  width: calc(100% - 40px);
}

.coxswain-module-new select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMjAgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU3LjEgKDgzMDg4KSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5jYXJib24tY2hldnJvbi1kb3duPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImNhcmJvbi1jaGV2cm9uLWRvd24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02LjAwMDAwMCwgLTEwLjAwMDAwMCkiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxnIGlkPSJJY29uIiBmaWxsPSIjMDAwMDAwIj4KICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJJY29uLVBhdGgiIHBvaW50cz0iMTYgMjIgNiAxMiA3LjQgMTAuNiAxNiAxOS4yIDI0LjYgMTAuNiAyNiAxMiI+PC9wb2x5Z29uPgogICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDxyZWN0IGlkPSJWaWV3Qm94IiB4PSIwIiB5PSIwIiB3aWR0aD0iMzIiIGhlaWdodD0iMzIiPjwvcmVjdD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 20px) center;
}

.coxswain-module-new input[type="submit"] {
  margin-top: 0;
  margin-bottom: 0;
}

.coxswain-module-new input[type="file"] {
  margin-bottom: 0;
}

.coxswain-module-new .mce-tinymce.mce-container.mce-panel {
  border: 1px solid black;
}

.coxswain-module-new .coxswain-bottom-buttons {
  bottom: 0;
  width: 100%;
  position: absolute;
  text-align: right;
}

.coxswain-module-new .coxswain-bottom-buttons input {
  background-color: #2699FB;
  margin-left: 20px;
}

.coxswain-module-new .coxswain-bottom-buttons span {
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.coxswain-module-new .coxswain-bottom-buttons span:hover,
.coxswain-module-new .coxswain-bottom-buttons span:focus {
  color: #2699FB;
}

.coxswain-module-new .coxswain-module-name {
  top: 0;
  width: 100%;
  position: absolute;
  z-index: 10;
}

.coxswain {
  font-family: 'Inter', serif !important;
  letter-spacing: 0.3px;
}

.coxswain *:not(.mce-ico) {
  font-family: 'Inter', serif !important;
}

.coxswain .box-shadow {
  box-shadow: 0 4px 18px 3px rgba(0,0,0,0.12),0 10px 14px 1px rgba(0,0,0,0.14),0 6px 6px -3px rgba(0,0,0,0.2);
}

.coxswain form {
  display: block;
}

.coxswain h2 {
  line-height: 1.2;
  font-size: 2rem;
  margin-bottom: 60px;
  padding: 0px 10px;
  text-transform: uppercase;
}

.coxswain label {
  color: #2699FB;
  margin: 0;
  letter-spacing: 0.3px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 12px;
}

.coxswain input,
.coxswain select {
  border: none;
  color: #2699FB;
  display: block;
  margin-bottom: 10px;
  padding: 10px 10px;
  letter-spacing: 0.3px;
  border-radius: 2px;
  font-size: 16px;
}

.coxswain input:focus,
.coxswain select:focus {
  color: black;
}

.coxswain input+label,
.coxswain select+label {
  -webkit-transform: translateY(-75px);
          transform: translateY(-75px);
  padding: 0px 10px;
  color: black;
}

.coxswain .error {
  display: block;
  margin-top: -30px;
  color: red;
  padding: 0px 10px;
}

.coxswain input[type="submit"] {
  background-color: var(--highlight);
  text-transform: uppercase;
  color: black;
  width: auto;
  height: 100%;
  display: inline-block;
  letter-spacing: 0.8px;
  line-height: 1;
  font-size: 14px;
}

.coxswain input[type="submit"]:hover,
.coxswain input[type="submit"]:focus {
  box-shadow: 0 4px 18px 3px rgba(0,0,0,0.12),0 10px 14px 1px rgba(0,0,0,0.14),0 6px 6px -3px rgba(0,0,0,0.2);
  color: white;
}

.coxswain-login {
  position: fixed;
  border: 2px solid black;
  box-shadow: 0 4px 18px 3px rgba(0,0,0,0.12),0 10px 14px 1px rgba(0,0,0,0.14),0 6px 6px -3px rgba(0,0,0,0.2);
  top: 0;
  left: 0;
  right: 0;
  margin-top: 100px;
  margin: 100px auto;
  width: 400px;
  padding: 20px;
  padding-bottom: 0;
  background: white;
  z-index: 10000;
}

.coxswain-login h2 {
  margin-bottom: 40px;
}

.coxswain-login input[type="email"],
.coxswain-login input[type="password"] {
  width: calc(100% - 40px);
}

.coxswain-login input+label {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.coxswain-login label {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.coxswain-login .submit-container {
  margin-top: 20px;
  position: relative;
  bottom: 0;
  left: -20px;
  width: 398px;
  height: auto;
  background-color: black;
}

.coxswain-login .submit-container input[type="submit"] {
  font-weight: 700;
  position: relative;
  top: 1px;
  left: calc(80% - 2px);
  margin-bottom: 0;
}

.coxswain-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10000;
  background: black;
  padding: 0px;
  color: white;
  height: 54px;
  display: flex;
  justify-content: space-between;
}

.coxswain-bar.coxswain-bar-block {
  display: block;
  padding: 20px;
}

.coxswain-bar.coxswain-bar-block a {
  padding: 10px;
}

.coxswain-bar * {
  font-family: sans-serif !important;
}

.coxswain-bar a {
  color: white;
  font-weight: bold;
  padding: 20px;
  display: inline-block;
}

.coxswain-bar a.publish {
  color: black;
  background-color: #2699FB;
}

.coxswain-bar a:hover {
  background-color: #222;
  color: #2699FB;
}

.coxswain-editpage-bar {
  top: 54px;
  background: #111;
}

.coxswain-editpages-view {
  top: 54px;
  background: #f9f9f9;
  color: black;
  height: auto;
  max-height: calc(100vh - 54px);
  padding-bottom: 80px;
  overflow-y: scroll;
}

.coxswain-editpages-view.thirdlevel {
  top: 108px;
}

.coxswain-editpages-view .editpages-title {
  border: 1px solid rgba(0,0,0,0.1);
  padding: 10px;
  margin-top: 10px;
}

.coxswain-editpages-view .editpages-title:hover {
  background: white;
}

.coxswain-editpages-view .editpages-title:hover a {
  opacity: 1;
  transition-delay: 0.2s;
}

.coxswain-editpages-view .editpages-title strong {
  margin-right: 40px;
  min-width: 180px;
  display: inline-block;
}

.coxswain-editpages-view .editpages-title a {
  color: #2699FB;
  margin-left: 10px;
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.coxswain-editpages-view input+label,
.coxswain-editpages-view select+label {
  -webkit-transform: none;
          transform: none;
}


.dam-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.dam-container .loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.25);
  z-index: 1;
}

.dam-header {
  width: 100%;
  background: black;
  color: white;
  display: flex;
  flex-direction: row-reverse;
}

.dam-header .confirm,
.dam-header .close {
  padding: 20px;
  font-weight: bold;
}

.dam-header .confirm {
  background-color: var(--highlight);
  color: black;
}

.dam-inner-container {
  height: calc(100vh - 254px);
  overflow-y: scroll;
}

.dam-listing>.dam-image,
.dam-listing>.dam-video,
.dam-listing>.dam-file {
  float: left;
}

.dam-listing:after {
  content: '';
  clear: both;
  float: none;
}

.dam-image,
.dam-video,
.dam-file {
  display: block;
  width: 150px;
  height: 150px;
  -o-object-fit: cover;
     object-fit: cover;
  border: 5px solid white;
  position: relative;
}

.dam-image:hover,
.dam-video:hover,
.dam-file:hover {
  border: 5px solid var(--highlight);
  cursor: pointer;
}

.dam-image:hover:after,
.dam-video:hover:after,
.dam-file:hover:after {
  content: 'Click to select this image';
  position: absolute;
  top: 0px;
  border: 1px solid red;
  width: 100px;
  z-index: 10001;
}

.dam-image.selected-true,
.dam-video.selected-true,
.dam-file.selected-true {
  border: 5px solid red;
}

.dam-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selectable-url {
  overflow: hidden;
  padding: 5px;
  border: 1px solid var(--fore);
  position: relative;
  height: 30px;
  line-height: 1.6;
  white-space: pre;
  width: 250px;
  max-width: 250px;
  overflow-x: auto;
}

.selectable-url .selectable-url-preview {
  display: block;
  overflow-x: auto;
  width: 100%;
  max-width: 250px;
  padding-right: 25px;
}

.selectable-url .selectable-url-copy {
  position: absolute;
  right: 0px;
  top: 0px;
  background: var(--fore);
  color: var(--back);
  padding: 5px;
}

.dam-current {
  display: flex;
}

.dam-current .dam-detail {
  padding: 0px;
  margin-bottom: 10px;
}

.dam-current img,
.dam-current video {
  width: 300px;
  max-height: 150px;
  max-width: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}

.dam-selected-item {
  width: 50%;
  height: 100%;
  padding: 10px 10px;
}

.dam-selected-item .dam-current-details {
  width: 50%;
}

.dam-dropzone {
  height: 100%;
  padding: 10px 10px;
  width: 50%;
}

.dam-dropzone .dam-dropzone-inner {
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed rgba(255,255,255,0.3);
}

.dam-dropzone .dam-dropzone-inner .dam-dropzone-inner-hotdrop {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dam-heading {
  font-size: 18px;
  margin-bottom: 10px;
}

.dam-dropzone-container {
  --back: black;
  --fore: white;
  height: 200px;
  background: black;
  color: white;
}

.dam-dropzone-container>img {
  width: 150px !important;
}

.upload-dam {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.upload-dam img {
  margin-right: 20px;
}

.dam-button {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.dam-button .dam-open-dam,
.dam-button .dam-remove-dam {
  background-color: var(--highlight);
  padding: 8px 20px;
  font-weight: bold;
}

.dam-button .dam-remove-dam {
  background-color: var(--c-senary);
  color: var(--c-background);
}


.coxswain-editable-pagemodule {
  position: relative;
}

.coxswain-editable-pagemodule .editable-pagemodule-toggle {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coxswain-editable-pagemodule .editable-pagemodule-toggle:hover {
  opacity: 1;
}

.coxswain-editable-pagemodule .editable-pagemodule-toggle .edit-pagemodule-button-group {
  width: 100%;
  display: flex;
  max-width: 600px;
  margin: 0 auto;
  justify-content: center;
}

.coxswain-editable-pagemodule .editable-pagemodule-toggle .edit-pagemodule-button-group a {
  background: white;
  color: #2699FB;
  padding: 10px;
  margin: 10px;
  border: 2px solid black;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 3px;
}

.coxswain-editable-pagemodule .editable-pagemodule-toggle .edit-pagemodule-button-group a:hover,
.coxswain-editable-pagemodule .editable-pagemodule-toggle .edit-pagemodule-button-group a:focus {
  background: #2699FB;
  color: white;
}

.coxswain-module-form {
  position: fixed;
  top: 100px;
  z-index: 10001;
  width: 600px;
  background: white;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: auto;
  box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
  border-radius: 3px;
}

.coxswain-module-form .coxswain-inputs {
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
  padding: 30px 20px;
}

.coxswain-module-form .coxswain-url-field {
  width: 100%;
}

.coxswain-module-form.addmodule-modal {
  min-width: 100vw;
  min-height: 100vh;
  top: 54px;
  box-shadow: none;
}

.coxswain-module-form.addmodule-modal .addmodule-inner {
  display: flex;
  flex-wrap: wrap;
  max-width: 1024px;
  margin: 0 auto;
}

.coxswain-module-form.addmodule-modal .addmodule-single {
  width: calc(25% - 20px);
  margin: 0px 10px 10px 0px;
  border-radius: 3px;
  padding: 10px;
  background: white;
  border: 1px solid black;
}

.coxswain-module-form.addmodule-modal .addmodule-single:hover,
.coxswain-module-form.addmodule-modal .addmodule-single.selected-true {
  border: 1px solid #2699FB;
  color: #2699fb;
  cursor: pointer;
  box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
}

.coxswain-module-form.addmodule-modal .addmodule-single p {
  line-height: 1.3;
  margin: 10px 0px;
}

.coxswain-module-form.addmodule-modal .addmodule-single .addmodule-single-tag {
  display: inline-block;
  margin-right: 5px;
  padding: 2px 3px;
  color: white;
  background: #2699FB;
  border-radius: 2px;
  text-transform: uppercase;
}

.coxswain-module-name {
  text-transform: uppercase;
}


@-webkit-keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ril__outer {
  background-color: rgba(0, 0, 0, 0.85);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  -ms-touch-action: none;
      touch-action: none;
}

.ril__outerClosing {
  opacity: 0;
}

.ril__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  -ms-touch-action: none;
      touch-action: none;
}

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 34px;
  padding: 40px 30px;
  margin: auto;
  cursor: pointer;
  opacity: 0.7;
}
.ril__navButtons:hover {
  opacity: 1;
}
.ril__navButtons:active {
  opacity: 0.7;
}

.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==')
    no-repeat center;
}

.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+')
    no-repeat center;
}

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  background-size: cover;
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto;
}

.ril__captionContent {
  padding: 10px 20px;
  color: #fff;
}

.ril__toolbar {
  top: 0;
  height: 50px;
}

.ril__toolbarSide {
  height: 50px;
  margin: 0;
}

.ril__toolbarLeftSide {
  padding-left: 20px;
  padding-right: 0;
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ril__toolbarRightSide {
  padding-left: 0;
  padding-right: 20px;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
}

.ril__toolbarItem {
  display: inline-block;
  line-height: 50px;
  padding: 0;
  color: #fff;
  font-size: 120%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ril__toolbarItemChild {
  vertical-align: middle;
}

.ril__builtinButton {
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
}
.ril__builtinButton:hover {
  opacity: 1;
}
.ril__builtinButton:active {
  outline: none;
}

.ril__builtinButtonDisabled {
  cursor: default;
  opacity: 0.5;
}
.ril__builtinButtonDisabled:hover {
  opacity: 0.5;
}

.ril__closeButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
    no-repeat center;
}

.ril__zoomInButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
    no-repeat center;
}

.ril__zoomOutButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
    no-repeat center;
}

.ril__outerAnimating {
  -webkit-animation-name: closeWindow;
          animation-name: closeWindow;
}

@-webkit-keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.ril__loadingCircle {
  width: 60px;
  height: 60px;
  position: relative;
}

.ril__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.ril__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  -webkit-animation: pointFade 800ms infinite ease-in-out both;
          animation: pointFade 800ms infinite ease-in-out both;
}
.ril__loadingCirclePoint:nth-of-type(1) {
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
}
.ril__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(1)::before,
.ril__loadingCirclePoint:nth-of-type(7)::before {
  -webkit-animation-delay: -800ms;
          animation-delay: -800ms;
}
.ril__loadingCirclePoint:nth-of-type(2) {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.ril__loadingCirclePoint:nth-of-type(8) {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.ril__loadingCirclePoint:nth-of-type(2)::before,
.ril__loadingCirclePoint:nth-of-type(8)::before {
  -webkit-animation-delay: -666ms;
          animation-delay: -666ms;
}
.ril__loadingCirclePoint:nth-of-type(3) {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.ril__loadingCirclePoint:nth-of-type(9) {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.ril__loadingCirclePoint:nth-of-type(3)::before,
.ril__loadingCirclePoint:nth-of-type(9)::before {
  -webkit-animation-delay: -533ms;
          animation-delay: -533ms;
}
.ril__loadingCirclePoint:nth-of-type(4) {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.ril__loadingCirclePoint:nth-of-type(10) {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.ril__loadingCirclePoint:nth-of-type(4)::before,
.ril__loadingCirclePoint:nth-of-type(10)::before {
  -webkit-animation-delay: -400ms;
          animation-delay: -400ms;
}
.ril__loadingCirclePoint:nth-of-type(5) {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.ril__loadingCirclePoint:nth-of-type(11) {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg);
}
.ril__loadingCirclePoint:nth-of-type(5)::before,
.ril__loadingCirclePoint:nth-of-type(11)::before {
  -webkit-animation-delay: -266ms;
          animation-delay: -266ms;
}
.ril__loadingCirclePoint:nth-of-type(6) {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.ril__loadingCirclePoint:nth-of-type(12) {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg);
}
.ril__loadingCirclePoint:nth-of-type(6)::before,
.ril__loadingCirclePoint:nth-of-type(12)::before {
  -webkit-animation-delay: -133ms;
          animation-delay: -133ms;
}
.ril__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(13) {
  -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
          transform: rotate(360deg);
}
.ril__loadingCirclePoint:nth-of-type(7)::before,
.ril__loadingCirclePoint:nth-of-type(13)::before {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
}

.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none;
}

.ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  color: #fff;
}
.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
  display: none;
}

.ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

.leasingbutton {
  background-color: var(--c-primary);
  height: calc(100% + var(--spacing-md) + var(--spacing-md));
  position: absolute;
  right: 0;
  padding: 0 var(--spacing-md);
  display: flex;
}


.related-links {
  max-width: 100vw;
  overflow: hidden;
}

.related-links .related-links-inner-wrapper {
  grid-column: 1 / span 12;
  max-width: var(--outer-container);
  padding-top: var(--spacing-md);
  padding-bottom: var(--spacing-xl);
}

.related-links.background-true {
  background-color: #f5f5f5;
}

.related-links .offset {
  display: none;
}

.related-links .listing-title {
  margin-bottom: var(--spacing-md);
}

.related-links article {
  display: block !important;
  width: 100%;
  position: relative;
}

.related-links article a {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: var(--spacing-sm);
  display: flex;
  align-items: flex-end;
  box-shadow: 0 4px 18px 3px rgba(0,0,0,0),0 10px 14px 1px rgba(0,0,0,0),0 6px 6px -3px rgba(0,0,0,0);
  transition: box-shadow 0.2s;
}

.related-links article img.featured-image,
.related-links article .placeholder-background {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
}

.related-links.image-view-featured_image article {
  padding-bottom: 76%;
}

.related-links.image-view-featured_image article a::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to top, rgba(0,0,0,0.6), rgba(0,0,0,0));
  z-index: -1;
  transition: box-shadow 0.2s;
}

.related-links.image-view-featured_image article:hover a {
  box-shadow: 0 4px 18px 3px rgba(0,0,0,0.3),0 10px 14px 1px rgba(0,0,0,0.14),0 6px 6px -3px rgba(0,0,0,0.2);
}

.related-links.image-view-featured_image article:hover a::after {
  box-shadow: inset 0px 0px 1px 3px var(--c-primary);
}

.related-links.image-view-thumbnail article {
  padding-bottom: 100%;
  background-color: #f5f5f5;
}

.related-links.image-view-thumbnail article a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.related-links.image-view-thumbnail article a img {
  margin-bottom: var(--spacing-sm);
}

.related-links.image-view-thumbnail article:hover a {
  box-shadow: 0 4px 18px 3px rgba(0,0,0,0.3),0 10px 14px 1px rgba(0,0,0,0.14),0 6px 6px -3px rgba(0,0,0,0.2),inset 0px 0px 1px 3px var(--c-primary);
}

.related-links.image-view-thumbnail article:hover a h3 {
  color: var(--c-primary);
}

.related-links .slider-container {
  max-width: 100vw;
}

.related-links .slick-slider {
  position: relative;
}

.related-links .slick-slider .slick-dots {
  display: flex !important;
  margin-top: var(--spacing-md);
  height: 5px;
}

.related-links .slick-slider .slick-dots li {
  list-style-type: none;
  background-color: #e6e6e6;
  flex-grow: 1;
  display: flex;
  height: 100%;
  position: relative;
}

.related-links .slick-slider .slick-dots li::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--c-primary);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.related-links .slick-slider .slick-dots li.slick-active::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.related-links .slick-slider .slick-dots button {
  font-size: 0px;
  padding: 0px;
  height: 100%;
  flex-grow: 1;
  -webkit-appearance: none;
  background: none;
  border: none;
}

.related-links .slick-slider .slick-slide {
  margin-right: var(--grid-gutter-x);
}

.related-links .slick-slider .slick-slide:last-child {
  margin-right: 0px;
}

.related-links .slick-slider .slick-track {
  display: flex;
}

.related-links .slick-slider .slick-arrow {
  text-decoration: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: solid 1px var(--c-quarternary);
  border-radius: 17.1px;
  padding: calc(var(--font-size-xs) / 2) var(--font-size-xs);
  font-size: 0px;
  width: 50px;
  height: 32px;
  position: absolute;
  z-index: 2;
  bottom: calc(var(--spacing-xl) * -1);
  right: var(--spacing-md);
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.related-links .slick-slider .slick-arrow::after {
  content: '';
  width: 8px;
  height: 12px;
  display: block;
  background-image: url(/static/media/ArrowDark.78114125.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 auto;
}

.related-links .slick-slider .slick-arrow:hover {
  border: solid 1px var(--c-primary);
  background-color: var(--c-primary);
}

.related-links .slick-slider .slick-arrow:hover::after {
  background-image: url(/static/media/ArrowWhite.b7f8dbf5.svg);
}

.related-links .slick-slider .slick-arrow.slick-prev {
  -webkit-transform: translateY(-100%) translateX(calc(-100% - 10px));
          transform: translateY(-100%) translateX(calc(-100% - 10px));
}

.related-links .slick-slider .slick-arrow.slick-next::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

@media (min-width: 768px) {
  .related-links .offset {
    display: block;
    grid-column: 1 / span 3;
  }

  .related-links .related-links-inner-wrapper {
    grid-column: 4 / span 10;
  }

  .related-links.background-true {
    background-color: transparent;
  }

  .related-links.background-true .related-links-inner-wrapper {
    position: relative;
  }

  .related-links.background-true .related-links-inner-wrapper::after {
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: calc(var(--spacing-lg) * -1);
    background-color: #f5f5f5;
    z-index: -1;
  }
}

@media (min-width: 1000px) {
  .related-links .slick-slider .slick-dots {
    width: 80%;
    margin-top: var(--spacing-lg);
  }

  .related-links .slick-slider .slick-arrow {
    right: 0px;
    bottom: calc((var(--spacing-lg) * -1) + 5px);
  }
}


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: var(--ff-body),-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
  vertical-align: baseline;
  box-sizing: border-box;
}

:focus {
  outline: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a:focus {
  outline: thin dotted;
}

a:active,
a:hover {
  outline: 0;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  background-color: var(--c-background);
  color: var(--c-foreground);
  font-family: var(--font-family-body);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-regular);
  line-height: var(--font-lineheight-body);
}

.cookiebanner {
  background-color: var(--c-foreground) !important;
  color: var(--c-background) !important;
  font-family: var(--font-family-body) !important;
  font-size: var(--font-size-body) !important;
  font-weight: var(--font-weight-regular) !important;
  line-height: var(--font-lineheight-body) !important;
  padding: var(--spacing-xs) !important;
  z-index: 4 !important;
  display: flex !important;
  flex-direction: column-reverse !important;
}

.cookiebanner .cookiebanner-close+span>a {
  display: none !important;
}

.cookiebanner p {
  margin-bottom: var(--spacing-xs);
}

.cookiebanner p>a {
  font-weight: var(--font-weight-semi) !important;
  color: var(--c-tertiary) !important;
  text-decoration: underline !important;
}

.grid .box .ah-start {
  align-self: flex-start;
}

.grid .box .av-center {
  align-self: center;
}

.grid .box .av-end {
  align-self: flex-end;
}

.grid .box .ah-center {
  margin-left: auto;
  margin-right: auto;
}

.grid .box .ah-end {
  margin-left: auto;
}

.td-u {
  text-decoration: underline;
}

.tt-uc {
  text-transform: uppercase;
}

.container {
  max-width: var(--container);
  width: 100%;
  padding-left: var(--container-gutter);
  padding-right: var(--container-gutter);
  margin-left: auto;
  margin-right: auto;
}

.inner-container {
  max-width: var(--inner-container);
  width: 100%;
}

.outer-container {
  max-width: var(--outer-container);
  width: 100%;
  padding-left: var(--container-gutter);
  padding-right: var(--container-gutter);
  margin-left: auto;
  margin-right: auto;
}

.with-left-highlight {
  position: relative;
}

.with-left-highlight:after {
  content: "";
  height: calc(100% - 16px);
  position: absolute;
  width: 2px;
  background-color: var(--c-tertiary);
  bottom: 0px;
  left: 2px;
  z-index: 1;
}

figure.with-gradient:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0.8;
  background-image: linear-gradient(to bottom, #000, rgba(0,0,0,0));
}

.vis-hidden,
.not-inview {
  opacity: 0;
  min-height: 300px;
}

.fa-left {
  text-align: left;
}

.fa-center {
  text-align: center;
}

.fa-right {
  text-align: right;
}

.fw-normal {
  font-weight: var(--font-weight-normal);
}

.fw-semi {
  font-weight: var(--font-weight-semi);
}

.fw-bold {
  font-weight: var(--font-weight-bold);
}

.block {
  display: "block";
}

.inline-block {
  display: "inline-block";
}

.flex {
  display: "flex";
}

.ff-b {
  font-family: var(--font-family-body);
}

.ff-h {
  font-family: var(--font-family-heading);
}

.sp-p-null {
  padding-top: var(--spacing-null);
  padding-bottom: var(--spacing-null);
}

.sp-m-null {
  margin-bottom: var(--spacing-null);
}

.fs-null {
  font-size: var(--font-size-null);
}

.sp-p-xs {
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
}

.sp-m-xs {
  margin-bottom: var(--spacing-xs);
}

.fs-xs {
  font-size: var(--font-size-xs);
}

.sp-p-sm {
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-sm);
}

.sp-m-sm {
  margin-bottom: var(--spacing-sm);
}

.fs-sm {
  font-size: var(--font-size-sm);
}

.sp-p-md {
  padding-top: var(--spacing-md);
  padding-bottom: var(--spacing-md);
}

.sp-m-md {
  margin-bottom: var(--spacing-md);
}

.fs-md {
  font-size: var(--font-size-md);
}

.sp-p-lg {
  padding-top: var(--spacing-lg);
  padding-bottom: var(--spacing-lg);
}

.sp-m-lg {
  margin-bottom: var(--spacing-lg);
}

.fs-lg {
  font-size: var(--font-size-lg);
}

.sp-p-xl {
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
}

.sp-m-xl {
  margin-bottom: var(--spacing-xl);
}

.fs-xl {
  font-size: var(--font-size-xl);
}

.sp-p-super {
  padding-top: var(--spacing-super);
  padding-bottom: var(--spacing-super);
}

.sp-m-super {
  margin-bottom: var(--spacing-super);
}

.fs-super {
  font-size: var(--font-size-super);
}

.c-background {
  color: var(--c-background);
}

.bg-background {
  background-color: var(--c-background);
}

.c-foreground {
  color: var(--c-foreground);
}

.bg-foreground {
  background-color: var(--c-foreground);
}

.c-primary {
  color: var(--c-primary);
}

.bg-primary {
  background-color: var(--c-primary);
}

.c-secondary {
  color: var(--c-secondary);
}

.bg-secondary {
  background-color: var(--c-secondary);
}

.c-tertiary {
  color: var(--c-tertiary);
}

.bg-tertiary {
  background-color: var(--c-tertiary);
}

.c-quarternary {
  color: var(--c-quarternary);
}

.bg-quarternary {
  background-color: var(--c-quarternary);
}

.c-quinary {
  color: var(--c-quinary);
}

.bg-quinary {
  background-color: var(--c-quinary);
}

@-webkit-keyframes stagger {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes stagger {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.inview-true h1:nth-child(1),
.inview-true h2:nth-child(1),
.inview-true h3:nth-child(1),
.inview-true h4:nth-child(1),
.inview-true h5:nth-child(1),
.inview-true h6:nth-child(1),
.inview-true p:nth-child(1),
.inview-true a:nth-child(1) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: .25s;
          animation-delay: .25s;
}

.inview-true h1:nth-child(2),
.inview-true h2:nth-child(2),
.inview-true h3:nth-child(2),
.inview-true h4:nth-child(2),
.inview-true h5:nth-child(2),
.inview-true h6:nth-child(2),
.inview-true p:nth-child(2),
.inview-true a:nth-child(2) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: .5s;
          animation-delay: .5s;
}

.inview-true h1:nth-child(3),
.inview-true h2:nth-child(3),
.inview-true h3:nth-child(3),
.inview-true h4:nth-child(3),
.inview-true h5:nth-child(3),
.inview-true h6:nth-child(3),
.inview-true p:nth-child(3),
.inview-true a:nth-child(3) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: .75s;
          animation-delay: .75s;
}

.inview-true h1:nth-child(4),
.inview-true h2:nth-child(4),
.inview-true h3:nth-child(4),
.inview-true h4:nth-child(4),
.inview-true h5:nth-child(4),
.inview-true h6:nth-child(4),
.inview-true p:nth-child(4),
.inview-true a:nth-child(4) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.inview-true h1:nth-child(5),
.inview-true h2:nth-child(5),
.inview-true h3:nth-child(5),
.inview-true h4:nth-child(5),
.inview-true h5:nth-child(5),
.inview-true h6:nth-child(5),
.inview-true p:nth-child(5),
.inview-true a:nth-child(5) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 1.25s;
          animation-delay: 1.25s;
}

.inview-true h1:nth-child(6),
.inview-true h2:nth-child(6),
.inview-true h3:nth-child(6),
.inview-true h4:nth-child(6),
.inview-true h5:nth-child(6),
.inview-true h6:nth-child(6),
.inview-true p:nth-child(6),
.inview-true a:nth-child(6) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

.inview-true h1:nth-child(7),
.inview-true h2:nth-child(7),
.inview-true h3:nth-child(7),
.inview-true h4:nth-child(7),
.inview-true h5:nth-child(7),
.inview-true h6:nth-child(7),
.inview-true p:nth-child(7),
.inview-true a:nth-child(7) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 1.75s;
          animation-delay: 1.75s;
}

.inview-true h1:nth-child(8),
.inview-true h2:nth-child(8),
.inview-true h3:nth-child(8),
.inview-true h4:nth-child(8),
.inview-true h5:nth-child(8),
.inview-true h6:nth-child(8),
.inview-true p:nth-child(8),
.inview-true a:nth-child(8) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.inview-true h1:nth-child(9),
.inview-true h2:nth-child(9),
.inview-true h3:nth-child(9),
.inview-true h4:nth-child(9),
.inview-true h5:nth-child(9),
.inview-true h6:nth-child(9),
.inview-true p:nth-child(9),
.inview-true a:nth-child(9) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 2.25s;
          animation-delay: 2.25s;
}

.inview-true h1:nth-child(10),
.inview-true h2:nth-child(10),
.inview-true h3:nth-child(10),
.inview-true h4:nth-child(10),
.inview-true h5:nth-child(10),
.inview-true h6:nth-child(10),
.inview-true p:nth-child(10),
.inview-true a:nth-child(10) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

.inview-true h1:nth-child(11),
.inview-true h2:nth-child(11),
.inview-true h3:nth-child(11),
.inview-true h4:nth-child(11),
.inview-true h5:nth-child(11),
.inview-true h6:nth-child(11),
.inview-true p:nth-child(11),
.inview-true a:nth-child(11) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 2.75s;
          animation-delay: 2.75s;
}

.inview-true h1:nth-child(12),
.inview-true h2:nth-child(12),
.inview-true h3:nth-child(12),
.inview-true h4:nth-child(12),
.inview-true h5:nth-child(12),
.inview-true h6:nth-child(12),
.inview-true p:nth-child(12),
.inview-true a:nth-child(12) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.inview-true h1:nth-child(13),
.inview-true h2:nth-child(13),
.inview-true h3:nth-child(13),
.inview-true h4:nth-child(13),
.inview-true h5:nth-child(13),
.inview-true h6:nth-child(13),
.inview-true p:nth-child(13),
.inview-true a:nth-child(13) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 3.25s;
          animation-delay: 3.25s;
}

.inview-true h1:nth-child(14),
.inview-true h2:nth-child(14),
.inview-true h3:nth-child(14),
.inview-true h4:nth-child(14),
.inview-true h5:nth-child(14),
.inview-true h6:nth-child(14),
.inview-true p:nth-child(14),
.inview-true a:nth-child(14) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 3.5s;
          animation-delay: 3.5s;
}

.inview-true h1:nth-child(15),
.inview-true h2:nth-child(15),
.inview-true h3:nth-child(15),
.inview-true h4:nth-child(15),
.inview-true h5:nth-child(15),
.inview-true h6:nth-child(15),
.inview-true p:nth-child(15),
.inview-true a:nth-child(15) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 3.75s;
          animation-delay: 3.75s;
}

.inview-true h1:nth-child(16),
.inview-true h2:nth-child(16),
.inview-true h3:nth-child(16),
.inview-true h4:nth-child(16),
.inview-true h5:nth-child(16),
.inview-true h6:nth-child(16),
.inview-true p:nth-child(16),
.inview-true a:nth-child(16) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}

.inview-true h1:nth-child(17),
.inview-true h2:nth-child(17),
.inview-true h3:nth-child(17),
.inview-true h4:nth-child(17),
.inview-true h5:nth-child(17),
.inview-true h6:nth-child(17),
.inview-true p:nth-child(17),
.inview-true a:nth-child(17) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 4.25s;
          animation-delay: 4.25s;
}

.inview-true h1:nth-child(18),
.inview-true h2:nth-child(18),
.inview-true h3:nth-child(18),
.inview-true h4:nth-child(18),
.inview-true h5:nth-child(18),
.inview-true h6:nth-child(18),
.inview-true p:nth-child(18),
.inview-true a:nth-child(18) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 4.5s;
          animation-delay: 4.5s;
}

.inview-true h1:nth-child(19),
.inview-true h2:nth-child(19),
.inview-true h3:nth-child(19),
.inview-true h4:nth-child(19),
.inview-true h5:nth-child(19),
.inview-true h6:nth-child(19),
.inview-true p:nth-child(19),
.inview-true a:nth-child(19) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 4.75s;
          animation-delay: 4.75s;
}

.inview-true h1:nth-child(20),
.inview-true h2:nth-child(20),
.inview-true h3:nth-child(20),
.inview-true h4:nth-child(20),
.inview-true h5:nth-child(20),
.inview-true h6:nth-child(20),
.inview-true p:nth-child(20),
.inview-true a:nth-child(20) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 5s;
          animation-delay: 5s;
}

.inview-true h1:nth-child(21),
.inview-true h2:nth-child(21),
.inview-true h3:nth-child(21),
.inview-true h4:nth-child(21),
.inview-true h5:nth-child(21),
.inview-true h6:nth-child(21),
.inview-true p:nth-child(21),
.inview-true a:nth-child(21) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 5.25s;
          animation-delay: 5.25s;
}

.inview-true h1:nth-child(22),
.inview-true h2:nth-child(22),
.inview-true h3:nth-child(22),
.inview-true h4:nth-child(22),
.inview-true h5:nth-child(22),
.inview-true h6:nth-child(22),
.inview-true p:nth-child(22),
.inview-true a:nth-child(22) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 5.5s;
          animation-delay: 5.5s;
}

.inview-true h1:nth-child(23),
.inview-true h2:nth-child(23),
.inview-true h3:nth-child(23),
.inview-true h4:nth-child(23),
.inview-true h5:nth-child(23),
.inview-true h6:nth-child(23),
.inview-true p:nth-child(23),
.inview-true a:nth-child(23) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 5.75s;
          animation-delay: 5.75s;
}

.inview-true h1:nth-child(24),
.inview-true h2:nth-child(24),
.inview-true h3:nth-child(24),
.inview-true h4:nth-child(24),
.inview-true h5:nth-child(24),
.inview-true h6:nth-child(24),
.inview-true p:nth-child(24),
.inview-true a:nth-child(24) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 6s;
          animation-delay: 6s;
}

.inview-true h1:nth-child(25),
.inview-true h2:nth-child(25),
.inview-true h3:nth-child(25),
.inview-true h4:nth-child(25),
.inview-true h5:nth-child(25),
.inview-true h6:nth-child(25),
.inview-true p:nth-child(25),
.inview-true a:nth-child(25) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 6.25s;
          animation-delay: 6.25s;
}

.inview-true h1:nth-child(26),
.inview-true h2:nth-child(26),
.inview-true h3:nth-child(26),
.inview-true h4:nth-child(26),
.inview-true h5:nth-child(26),
.inview-true h6:nth-child(26),
.inview-true p:nth-child(26),
.inview-true a:nth-child(26) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 6.5s;
          animation-delay: 6.5s;
}

.inview-true h1:nth-child(27),
.inview-true h2:nth-child(27),
.inview-true h3:nth-child(27),
.inview-true h4:nth-child(27),
.inview-true h5:nth-child(27),
.inview-true h6:nth-child(27),
.inview-true p:nth-child(27),
.inview-true a:nth-child(27) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 6.75s;
          animation-delay: 6.75s;
}

.inview-true h1:nth-child(28),
.inview-true h2:nth-child(28),
.inview-true h3:nth-child(28),
.inview-true h4:nth-child(28),
.inview-true h5:nth-child(28),
.inview-true h6:nth-child(28),
.inview-true p:nth-child(28),
.inview-true a:nth-child(28) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}

.inview-true h1:nth-child(29),
.inview-true h2:nth-child(29),
.inview-true h3:nth-child(29),
.inview-true h4:nth-child(29),
.inview-true h5:nth-child(29),
.inview-true h6:nth-child(29),
.inview-true p:nth-child(29),
.inview-true a:nth-child(29) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 7.25s;
          animation-delay: 7.25s;
}

.inview-true h1:nth-child(30),
.inview-true h2:nth-child(30),
.inview-true h3:nth-child(30),
.inview-true h4:nth-child(30),
.inview-true h5:nth-child(30),
.inview-true h6:nth-child(30),
.inview-true p:nth-child(30),
.inview-true a:nth-child(30) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 7.5s;
          animation-delay: 7.5s;
}

.inview-true h1:nth-child(31),
.inview-true h2:nth-child(31),
.inview-true h3:nth-child(31),
.inview-true h4:nth-child(31),
.inview-true h5:nth-child(31),
.inview-true h6:nth-child(31),
.inview-true p:nth-child(31),
.inview-true a:nth-child(31) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 7.75s;
          animation-delay: 7.75s;
}

.inview-true h1:nth-child(32),
.inview-true h2:nth-child(32),
.inview-true h3:nth-child(32),
.inview-true h4:nth-child(32),
.inview-true h5:nth-child(32),
.inview-true h6:nth-child(32),
.inview-true p:nth-child(32),
.inview-true a:nth-child(32) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 8s;
          animation-delay: 8s;
}

.inview-true h1:nth-child(33),
.inview-true h2:nth-child(33),
.inview-true h3:nth-child(33),
.inview-true h4:nth-child(33),
.inview-true h5:nth-child(33),
.inview-true h6:nth-child(33),
.inview-true p:nth-child(33),
.inview-true a:nth-child(33) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 8.25s;
          animation-delay: 8.25s;
}

.inview-true h1:nth-child(34),
.inview-true h2:nth-child(34),
.inview-true h3:nth-child(34),
.inview-true h4:nth-child(34),
.inview-true h5:nth-child(34),
.inview-true h6:nth-child(34),
.inview-true p:nth-child(34),
.inview-true a:nth-child(34) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 8.5s;
          animation-delay: 8.5s;
}

.inview-true h1:nth-child(35),
.inview-true h2:nth-child(35),
.inview-true h3:nth-child(35),
.inview-true h4:nth-child(35),
.inview-true h5:nth-child(35),
.inview-true h6:nth-child(35),
.inview-true p:nth-child(35),
.inview-true a:nth-child(35) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 8.75s;
          animation-delay: 8.75s;
}

.inview-true h1:nth-child(36),
.inview-true h2:nth-child(36),
.inview-true h3:nth-child(36),
.inview-true h4:nth-child(36),
.inview-true h5:nth-child(36),
.inview-true h6:nth-child(36),
.inview-true p:nth-child(36),
.inview-true a:nth-child(36) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 9s;
          animation-delay: 9s;
}

.inview-true h1:nth-child(37),
.inview-true h2:nth-child(37),
.inview-true h3:nth-child(37),
.inview-true h4:nth-child(37),
.inview-true h5:nth-child(37),
.inview-true h6:nth-child(37),
.inview-true p:nth-child(37),
.inview-true a:nth-child(37) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 9.25s;
          animation-delay: 9.25s;
}

.inview-true h1:nth-child(38),
.inview-true h2:nth-child(38),
.inview-true h3:nth-child(38),
.inview-true h4:nth-child(38),
.inview-true h5:nth-child(38),
.inview-true h6:nth-child(38),
.inview-true p:nth-child(38),
.inview-true a:nth-child(38) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 9.5s;
          animation-delay: 9.5s;
}

.inview-true h1:nth-child(39),
.inview-true h2:nth-child(39),
.inview-true h3:nth-child(39),
.inview-true h4:nth-child(39),
.inview-true h5:nth-child(39),
.inview-true h6:nth-child(39),
.inview-true p:nth-child(39),
.inview-true a:nth-child(39) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 9.75s;
          animation-delay: 9.75s;
}

.inview-true h1:nth-child(40),
.inview-true h2:nth-child(40),
.inview-true h3:nth-child(40),
.inview-true h4:nth-child(40),
.inview-true h5:nth-child(40),
.inview-true h6:nth-child(40),
.inview-true p:nth-child(40),
.inview-true a:nth-child(40) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 10s;
          animation-delay: 10s;
}

.inview-true h1:nth-child(41),
.inview-true h2:nth-child(41),
.inview-true h3:nth-child(41),
.inview-true h4:nth-child(41),
.inview-true h5:nth-child(41),
.inview-true h6:nth-child(41),
.inview-true p:nth-child(41),
.inview-true a:nth-child(41) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 10.25s;
          animation-delay: 10.25s;
}

.inview-true h1:nth-child(42),
.inview-true h2:nth-child(42),
.inview-true h3:nth-child(42),
.inview-true h4:nth-child(42),
.inview-true h5:nth-child(42),
.inview-true h6:nth-child(42),
.inview-true p:nth-child(42),
.inview-true a:nth-child(42) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 10.5s;
          animation-delay: 10.5s;
}

.inview-true h1:nth-child(43),
.inview-true h2:nth-child(43),
.inview-true h3:nth-child(43),
.inview-true h4:nth-child(43),
.inview-true h5:nth-child(43),
.inview-true h6:nth-child(43),
.inview-true p:nth-child(43),
.inview-true a:nth-child(43) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 10.75s;
          animation-delay: 10.75s;
}

.inview-true h1:nth-child(44),
.inview-true h2:nth-child(44),
.inview-true h3:nth-child(44),
.inview-true h4:nth-child(44),
.inview-true h5:nth-child(44),
.inview-true h6:nth-child(44),
.inview-true p:nth-child(44),
.inview-true a:nth-child(44) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 11s;
          animation-delay: 11s;
}

.inview-true h1:nth-child(45),
.inview-true h2:nth-child(45),
.inview-true h3:nth-child(45),
.inview-true h4:nth-child(45),
.inview-true h5:nth-child(45),
.inview-true h6:nth-child(45),
.inview-true p:nth-child(45),
.inview-true a:nth-child(45) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 11.25s;
          animation-delay: 11.25s;
}

.inview-true h1:nth-child(46),
.inview-true h2:nth-child(46),
.inview-true h3:nth-child(46),
.inview-true h4:nth-child(46),
.inview-true h5:nth-child(46),
.inview-true h6:nth-child(46),
.inview-true p:nth-child(46),
.inview-true a:nth-child(46) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 11.5s;
          animation-delay: 11.5s;
}

.inview-true h1:nth-child(47),
.inview-true h2:nth-child(47),
.inview-true h3:nth-child(47),
.inview-true h4:nth-child(47),
.inview-true h5:nth-child(47),
.inview-true h6:nth-child(47),
.inview-true p:nth-child(47),
.inview-true a:nth-child(47) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 11.75s;
          animation-delay: 11.75s;
}

.inview-true h1:nth-child(48),
.inview-true h2:nth-child(48),
.inview-true h3:nth-child(48),
.inview-true h4:nth-child(48),
.inview-true h5:nth-child(48),
.inview-true h6:nth-child(48),
.inview-true p:nth-child(48),
.inview-true a:nth-child(48) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 12s;
          animation-delay: 12s;
}

.inview-true h1:nth-child(49),
.inview-true h2:nth-child(49),
.inview-true h3:nth-child(49),
.inview-true h4:nth-child(49),
.inview-true h5:nth-child(49),
.inview-true h6:nth-child(49),
.inview-true p:nth-child(49),
.inview-true a:nth-child(49) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 12.25s;
          animation-delay: 12.25s;
}

.inview-true h1:nth-child(50),
.inview-true h2:nth-child(50),
.inview-true h3:nth-child(50),
.inview-true h4:nth-child(50),
.inview-true h5:nth-child(50),
.inview-true h6:nth-child(50),
.inview-true p:nth-child(50),
.inview-true a:nth-child(50) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 12.5s;
          animation-delay: 12.5s;
}

.inview-true h1:nth-child(51),
.inview-true h2:nth-child(51),
.inview-true h3:nth-child(51),
.inview-true h4:nth-child(51),
.inview-true h5:nth-child(51),
.inview-true h6:nth-child(51),
.inview-true p:nth-child(51),
.inview-true a:nth-child(51) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 12.75s;
          animation-delay: 12.75s;
}

.inview-true h1:nth-child(52),
.inview-true h2:nth-child(52),
.inview-true h3:nth-child(52),
.inview-true h4:nth-child(52),
.inview-true h5:nth-child(52),
.inview-true h6:nth-child(52),
.inview-true p:nth-child(52),
.inview-true a:nth-child(52) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 13s;
          animation-delay: 13s;
}

.inview-true h1:nth-child(53),
.inview-true h2:nth-child(53),
.inview-true h3:nth-child(53),
.inview-true h4:nth-child(53),
.inview-true h5:nth-child(53),
.inview-true h6:nth-child(53),
.inview-true p:nth-child(53),
.inview-true a:nth-child(53) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 13.25s;
          animation-delay: 13.25s;
}

.inview-true h1:nth-child(54),
.inview-true h2:nth-child(54),
.inview-true h3:nth-child(54),
.inview-true h4:nth-child(54),
.inview-true h5:nth-child(54),
.inview-true h6:nth-child(54),
.inview-true p:nth-child(54),
.inview-true a:nth-child(54) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 13.5s;
          animation-delay: 13.5s;
}

.inview-true h1:nth-child(55),
.inview-true h2:nth-child(55),
.inview-true h3:nth-child(55),
.inview-true h4:nth-child(55),
.inview-true h5:nth-child(55),
.inview-true h6:nth-child(55),
.inview-true p:nth-child(55),
.inview-true a:nth-child(55) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 13.75s;
          animation-delay: 13.75s;
}

.inview-true h1:nth-child(56),
.inview-true h2:nth-child(56),
.inview-true h3:nth-child(56),
.inview-true h4:nth-child(56),
.inview-true h5:nth-child(56),
.inview-true h6:nth-child(56),
.inview-true p:nth-child(56),
.inview-true a:nth-child(56) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 14s;
          animation-delay: 14s;
}

.inview-true h1:nth-child(57),
.inview-true h2:nth-child(57),
.inview-true h3:nth-child(57),
.inview-true h4:nth-child(57),
.inview-true h5:nth-child(57),
.inview-true h6:nth-child(57),
.inview-true p:nth-child(57),
.inview-true a:nth-child(57) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 14.25s;
          animation-delay: 14.25s;
}

.inview-true h1:nth-child(58),
.inview-true h2:nth-child(58),
.inview-true h3:nth-child(58),
.inview-true h4:nth-child(58),
.inview-true h5:nth-child(58),
.inview-true h6:nth-child(58),
.inview-true p:nth-child(58),
.inview-true a:nth-child(58) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 14.5s;
          animation-delay: 14.5s;
}

.inview-true h1:nth-child(59),
.inview-true h2:nth-child(59),
.inview-true h3:nth-child(59),
.inview-true h4:nth-child(59),
.inview-true h5:nth-child(59),
.inview-true h6:nth-child(59),
.inview-true p:nth-child(59),
.inview-true a:nth-child(59) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 14.75s;
          animation-delay: 14.75s;
}

.inview-true h1:nth-child(60),
.inview-true h2:nth-child(60),
.inview-true h3:nth-child(60),
.inview-true h4:nth-child(60),
.inview-true h5:nth-child(60),
.inview-true h6:nth-child(60),
.inview-true p:nth-child(60),
.inview-true a:nth-child(60) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
}

.inview-true h1:nth-child(61),
.inview-true h2:nth-child(61),
.inview-true h3:nth-child(61),
.inview-true h4:nth-child(61),
.inview-true h5:nth-child(61),
.inview-true h6:nth-child(61),
.inview-true p:nth-child(61),
.inview-true a:nth-child(61) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 15.25s;
          animation-delay: 15.25s;
}

.inview-true h1:nth-child(62),
.inview-true h2:nth-child(62),
.inview-true h3:nth-child(62),
.inview-true h4:nth-child(62),
.inview-true h5:nth-child(62),
.inview-true h6:nth-child(62),
.inview-true p:nth-child(62),
.inview-true a:nth-child(62) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 15.5s;
          animation-delay: 15.5s;
}

.inview-true h1:nth-child(63),
.inview-true h2:nth-child(63),
.inview-true h3:nth-child(63),
.inview-true h4:nth-child(63),
.inview-true h5:nth-child(63),
.inview-true h6:nth-child(63),
.inview-true p:nth-child(63),
.inview-true a:nth-child(63) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 15.75s;
          animation-delay: 15.75s;
}

.inview-true h1:nth-child(64),
.inview-true h2:nth-child(64),
.inview-true h3:nth-child(64),
.inview-true h4:nth-child(64),
.inview-true h5:nth-child(64),
.inview-true h6:nth-child(64),
.inview-true p:nth-child(64),
.inview-true a:nth-child(64) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 16s;
          animation-delay: 16s;
}

.inview-true h1:nth-child(65),
.inview-true h2:nth-child(65),
.inview-true h3:nth-child(65),
.inview-true h4:nth-child(65),
.inview-true h5:nth-child(65),
.inview-true h6:nth-child(65),
.inview-true p:nth-child(65),
.inview-true a:nth-child(65) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 16.25s;
          animation-delay: 16.25s;
}

.inview-true h1:nth-child(66),
.inview-true h2:nth-child(66),
.inview-true h3:nth-child(66),
.inview-true h4:nth-child(66),
.inview-true h5:nth-child(66),
.inview-true h6:nth-child(66),
.inview-true p:nth-child(66),
.inview-true a:nth-child(66) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 16.5s;
          animation-delay: 16.5s;
}

.inview-true h1:nth-child(67),
.inview-true h2:nth-child(67),
.inview-true h3:nth-child(67),
.inview-true h4:nth-child(67),
.inview-true h5:nth-child(67),
.inview-true h6:nth-child(67),
.inview-true p:nth-child(67),
.inview-true a:nth-child(67) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 16.75s;
          animation-delay: 16.75s;
}

.inview-true h1:nth-child(68),
.inview-true h2:nth-child(68),
.inview-true h3:nth-child(68),
.inview-true h4:nth-child(68),
.inview-true h5:nth-child(68),
.inview-true h6:nth-child(68),
.inview-true p:nth-child(68),
.inview-true a:nth-child(68) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 17s;
          animation-delay: 17s;
}

.inview-true h1:nth-child(69),
.inview-true h2:nth-child(69),
.inview-true h3:nth-child(69),
.inview-true h4:nth-child(69),
.inview-true h5:nth-child(69),
.inview-true h6:nth-child(69),
.inview-true p:nth-child(69),
.inview-true a:nth-child(69) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 17.25s;
          animation-delay: 17.25s;
}

.inview-true h1:nth-child(70),
.inview-true h2:nth-child(70),
.inview-true h3:nth-child(70),
.inview-true h4:nth-child(70),
.inview-true h5:nth-child(70),
.inview-true h6:nth-child(70),
.inview-true p:nth-child(70),
.inview-true a:nth-child(70) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 17.5s;
          animation-delay: 17.5s;
}

.inview-true h1:nth-child(71),
.inview-true h2:nth-child(71),
.inview-true h3:nth-child(71),
.inview-true h4:nth-child(71),
.inview-true h5:nth-child(71),
.inview-true h6:nth-child(71),
.inview-true p:nth-child(71),
.inview-true a:nth-child(71) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 17.75s;
          animation-delay: 17.75s;
}

.inview-true h1:nth-child(72),
.inview-true h2:nth-child(72),
.inview-true h3:nth-child(72),
.inview-true h4:nth-child(72),
.inview-true h5:nth-child(72),
.inview-true h6:nth-child(72),
.inview-true p:nth-child(72),
.inview-true a:nth-child(72) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 18s;
          animation-delay: 18s;
}

.inview-true h1:nth-child(73),
.inview-true h2:nth-child(73),
.inview-true h3:nth-child(73),
.inview-true h4:nth-child(73),
.inview-true h5:nth-child(73),
.inview-true h6:nth-child(73),
.inview-true p:nth-child(73),
.inview-true a:nth-child(73) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 18.25s;
          animation-delay: 18.25s;
}

.inview-true h1:nth-child(74),
.inview-true h2:nth-child(74),
.inview-true h3:nth-child(74),
.inview-true h4:nth-child(74),
.inview-true h5:nth-child(74),
.inview-true h6:nth-child(74),
.inview-true p:nth-child(74),
.inview-true a:nth-child(74) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 18.5s;
          animation-delay: 18.5s;
}

.inview-true h1:nth-child(75),
.inview-true h2:nth-child(75),
.inview-true h3:nth-child(75),
.inview-true h4:nth-child(75),
.inview-true h5:nth-child(75),
.inview-true h6:nth-child(75),
.inview-true p:nth-child(75),
.inview-true a:nth-child(75) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 18.75s;
          animation-delay: 18.75s;
}

.inview-true h1:nth-child(76),
.inview-true h2:nth-child(76),
.inview-true h3:nth-child(76),
.inview-true h4:nth-child(76),
.inview-true h5:nth-child(76),
.inview-true h6:nth-child(76),
.inview-true p:nth-child(76),
.inview-true a:nth-child(76) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 19s;
          animation-delay: 19s;
}

.inview-true h1:nth-child(77),
.inview-true h2:nth-child(77),
.inview-true h3:nth-child(77),
.inview-true h4:nth-child(77),
.inview-true h5:nth-child(77),
.inview-true h6:nth-child(77),
.inview-true p:nth-child(77),
.inview-true a:nth-child(77) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 19.25s;
          animation-delay: 19.25s;
}

.inview-true h1:nth-child(78),
.inview-true h2:nth-child(78),
.inview-true h3:nth-child(78),
.inview-true h4:nth-child(78),
.inview-true h5:nth-child(78),
.inview-true h6:nth-child(78),
.inview-true p:nth-child(78),
.inview-true a:nth-child(78) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 19.5s;
          animation-delay: 19.5s;
}

.inview-true h1:nth-child(79),
.inview-true h2:nth-child(79),
.inview-true h3:nth-child(79),
.inview-true h4:nth-child(79),
.inview-true h5:nth-child(79),
.inview-true h6:nth-child(79),
.inview-true p:nth-child(79),
.inview-true a:nth-child(79) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 19.75s;
          animation-delay: 19.75s;
}

.inview-true h1:nth-child(80),
.inview-true h2:nth-child(80),
.inview-true h3:nth-child(80),
.inview-true h4:nth-child(80),
.inview-true h5:nth-child(80),
.inview-true h6:nth-child(80),
.inview-true p:nth-child(80),
.inview-true a:nth-child(80) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 20s;
          animation-delay: 20s;
}

.inview-true h1:nth-child(81),
.inview-true h2:nth-child(81),
.inview-true h3:nth-child(81),
.inview-true h4:nth-child(81),
.inview-true h5:nth-child(81),
.inview-true h6:nth-child(81),
.inview-true p:nth-child(81),
.inview-true a:nth-child(81) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 20.25s;
          animation-delay: 20.25s;
}

.inview-true h1:nth-child(82),
.inview-true h2:nth-child(82),
.inview-true h3:nth-child(82),
.inview-true h4:nth-child(82),
.inview-true h5:nth-child(82),
.inview-true h6:nth-child(82),
.inview-true p:nth-child(82),
.inview-true a:nth-child(82) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 20.5s;
          animation-delay: 20.5s;
}

.inview-true h1:nth-child(83),
.inview-true h2:nth-child(83),
.inview-true h3:nth-child(83),
.inview-true h4:nth-child(83),
.inview-true h5:nth-child(83),
.inview-true h6:nth-child(83),
.inview-true p:nth-child(83),
.inview-true a:nth-child(83) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 20.75s;
          animation-delay: 20.75s;
}

.inview-true h1:nth-child(84),
.inview-true h2:nth-child(84),
.inview-true h3:nth-child(84),
.inview-true h4:nth-child(84),
.inview-true h5:nth-child(84),
.inview-true h6:nth-child(84),
.inview-true p:nth-child(84),
.inview-true a:nth-child(84) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 21s;
          animation-delay: 21s;
}

.inview-true h1:nth-child(85),
.inview-true h2:nth-child(85),
.inview-true h3:nth-child(85),
.inview-true h4:nth-child(85),
.inview-true h5:nth-child(85),
.inview-true h6:nth-child(85),
.inview-true p:nth-child(85),
.inview-true a:nth-child(85) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 21.25s;
          animation-delay: 21.25s;
}

.inview-true h1:nth-child(86),
.inview-true h2:nth-child(86),
.inview-true h3:nth-child(86),
.inview-true h4:nth-child(86),
.inview-true h5:nth-child(86),
.inview-true h6:nth-child(86),
.inview-true p:nth-child(86),
.inview-true a:nth-child(86) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 21.5s;
          animation-delay: 21.5s;
}

.inview-true h1:nth-child(87),
.inview-true h2:nth-child(87),
.inview-true h3:nth-child(87),
.inview-true h4:nth-child(87),
.inview-true h5:nth-child(87),
.inview-true h6:nth-child(87),
.inview-true p:nth-child(87),
.inview-true a:nth-child(87) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 21.75s;
          animation-delay: 21.75s;
}

.inview-true h1:nth-child(88),
.inview-true h2:nth-child(88),
.inview-true h3:nth-child(88),
.inview-true h4:nth-child(88),
.inview-true h5:nth-child(88),
.inview-true h6:nth-child(88),
.inview-true p:nth-child(88),
.inview-true a:nth-child(88) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 22s;
          animation-delay: 22s;
}

.inview-true h1:nth-child(89),
.inview-true h2:nth-child(89),
.inview-true h3:nth-child(89),
.inview-true h4:nth-child(89),
.inview-true h5:nth-child(89),
.inview-true h6:nth-child(89),
.inview-true p:nth-child(89),
.inview-true a:nth-child(89) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 22.25s;
          animation-delay: 22.25s;
}

.inview-true h1:nth-child(90),
.inview-true h2:nth-child(90),
.inview-true h3:nth-child(90),
.inview-true h4:nth-child(90),
.inview-true h5:nth-child(90),
.inview-true h6:nth-child(90),
.inview-true p:nth-child(90),
.inview-true a:nth-child(90) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 22.5s;
          animation-delay: 22.5s;
}

.inview-true h1:nth-child(91),
.inview-true h2:nth-child(91),
.inview-true h3:nth-child(91),
.inview-true h4:nth-child(91),
.inview-true h5:nth-child(91),
.inview-true h6:nth-child(91),
.inview-true p:nth-child(91),
.inview-true a:nth-child(91) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 22.75s;
          animation-delay: 22.75s;
}

.inview-true h1:nth-child(92),
.inview-true h2:nth-child(92),
.inview-true h3:nth-child(92),
.inview-true h4:nth-child(92),
.inview-true h5:nth-child(92),
.inview-true h6:nth-child(92),
.inview-true p:nth-child(92),
.inview-true a:nth-child(92) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 23s;
          animation-delay: 23s;
}

.inview-true h1:nth-child(93),
.inview-true h2:nth-child(93),
.inview-true h3:nth-child(93),
.inview-true h4:nth-child(93),
.inview-true h5:nth-child(93),
.inview-true h6:nth-child(93),
.inview-true p:nth-child(93),
.inview-true a:nth-child(93) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 23.25s;
          animation-delay: 23.25s;
}

.inview-true h1:nth-child(94),
.inview-true h2:nth-child(94),
.inview-true h3:nth-child(94),
.inview-true h4:nth-child(94),
.inview-true h5:nth-child(94),
.inview-true h6:nth-child(94),
.inview-true p:nth-child(94),
.inview-true a:nth-child(94) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 23.5s;
          animation-delay: 23.5s;
}

.inview-true h1:nth-child(95),
.inview-true h2:nth-child(95),
.inview-true h3:nth-child(95),
.inview-true h4:nth-child(95),
.inview-true h5:nth-child(95),
.inview-true h6:nth-child(95),
.inview-true p:nth-child(95),
.inview-true a:nth-child(95) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 23.75s;
          animation-delay: 23.75s;
}

.inview-true h1:nth-child(96),
.inview-true h2:nth-child(96),
.inview-true h3:nth-child(96),
.inview-true h4:nth-child(96),
.inview-true h5:nth-child(96),
.inview-true h6:nth-child(96),
.inview-true p:nth-child(96),
.inview-true a:nth-child(96) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 24s;
          animation-delay: 24s;
}

.inview-true h1:nth-child(97),
.inview-true h2:nth-child(97),
.inview-true h3:nth-child(97),
.inview-true h4:nth-child(97),
.inview-true h5:nth-child(97),
.inview-true h6:nth-child(97),
.inview-true p:nth-child(97),
.inview-true a:nth-child(97) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 24.25s;
          animation-delay: 24.25s;
}

.inview-true h1:nth-child(98),
.inview-true h2:nth-child(98),
.inview-true h3:nth-child(98),
.inview-true h4:nth-child(98),
.inview-true h5:nth-child(98),
.inview-true h6:nth-child(98),
.inview-true p:nth-child(98),
.inview-true a:nth-child(98) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 24.5s;
          animation-delay: 24.5s;
}

.inview-true h1:nth-child(99),
.inview-true h2:nth-child(99),
.inview-true h3:nth-child(99),
.inview-true h4:nth-child(99),
.inview-true h5:nth-child(99),
.inview-true h6:nth-child(99),
.inview-true p:nth-child(99),
.inview-true a:nth-child(99) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 24.75s;
          animation-delay: 24.75s;
}

.inview-true h1:nth-child(100),
.inview-true h2:nth-child(100),
.inview-true h3:nth-child(100),
.inview-true h4:nth-child(100),
.inview-true h5:nth-child(100),
.inview-true h6:nth-child(100),
.inview-true p:nth-child(100),
.inview-true a:nth-child(100) {
  -webkit-animation: stagger;
          animation: stagger;
  -webkit-animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
          animation-timing-function: cubic-bezier(0.18, 1.06, 0.66, 1.01);
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 25s;
          animation-delay: 25s;
}

.a-swipe {
  position: relative;
}

.a-swipe.a-up:before {
  top: 0;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  -webkit-transform-origin: top;
          transform-origin: top;
}

.a-swipe.a-left:before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: left;
          transform-origin: left;
}

.a-swipe:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  left: 0;
  transition: 0.4s ease-in-out transform;
  transition-delay: 0.4s;
  z-index: 3;
}

.inview-false h1,
.inview-false h2,
.inview-false h3,
.inview-false h4,
.inview-false h5,
.inview-false h6,
.inview-false p,
.inview-false a,
.inview-true h1,
.inview-true h2,
.inview-true h3,
.inview-true h4,
.inview-true h5,
.inview-true h6,
.inview-true p,
.inview-true a {
  opacity: 0;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}

.inview-true .a-swipe.a-up::before {
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}

.inview-true .a-swipe.a-left::before {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}

h1,
.level-1 {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-super);
  line-height: var(--font-lineheight-super);
  font-weight: var(--font-weight-bold);
}

h2,
.level-2 {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-xl);
  line-height: var(--font-lineheight-xl);
  font-weight: var(--font-weight-bold);
}

h3,
.level-3 {
  font-family: var(--font-family-body);
  font-size: var(--font-size-xl);
  line-height: var(--font-lineheight-xl);
  font-weight: var(--font-weight-semi);
}

h4,
.level-4 {
  font-family: var(--font-family-body);
  font-size: var(--font-size-lg);
  line-height: var(--font-lineheight-lg);
  font-weight: var(--font-weight-semi);
}

h5,
.level-5 {
  font-family: var(--font-family-body);
  font-size: var(--font-size-lg);
  line-height: var(--font-lineheight-lg);
  font-weight: var(--font-weight-regular);
}

p,
ul,
li,
ol {
  font-size: var(--font-size-xs);
  line-height: var(--font-lineheight-xs);
}

ul {
  list-style: disc;
}

strong {
  font-weight: var(--font-weight-bold);
}

blockquote {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-lg);
  line-height: var(--font-lineheight-lg);
  margin-bottom: var(--spacing-sm);
  padding-left: var(--spacing-md);
  quotes: "“" "”" "‘" "’";
  font-style: italic;
  position: relative;
}

blockquote::before {
  content: open-quote;
  color: var(--c-primary);
  font-size: var(--font-size-super);
  line-height: var(--font-lineheight-super);
  position: absolute;
  left: 0px;
}

a {
  color: var(--c-primary);
  text-decoration: none;
}

hr {
  height: 2px;
  background-color: var(--c-primary);
  outline: none;
  border: none;
}

.button,
button.button,
a.button {
  -webkit-appearance: none;
  background: none;
  border: none;
  display: inline-block;
  font-weight: var(--font-weight-semi);
  padding: 13px 34px;
  border-radius: 22px;
  text-decoration: none;
}

.button.style-primary,
button.button.style-primary,
a.button.style-primary {
  background-color: var(--c-primary);
  border: solid 1px transparent;
  color: var(--c-background);
}

.button.style-secondary,
button.button.style-secondary,
a.button.style-secondary {
  background-color: var(--c-secondary);
  border: solid 1px transparent;
  color: var(--c-foreground);
}

.button.style-tertiary,
button.button.style-tertiary,
a.button.style-tertiary {
  background-color: var(--c-background);
  border: solid 1px var(--c-quarternary);
  color: var(--c-foreground);
}

.button.style-small,
button.button.style-small,
a.button.style-small {
  padding: 8px 18px;
  font-size: var(--font-size-xs);
  text-transform: uppercase;
  line-height: 1;
  border-radius: 14px;
}

.button.background-transparent,
button.button.background-transparent,
a.button.background-transparent {
  background-color: transparent;
}

.button.hover-primary:hover,
button.button.hover-primary:hover,
a.button.hover-primary:hover {
  background-color: var(--c-primary);
  border: solid 1px var(--c-primary);
  color: var(--c-background);
}

.button:hover,
button.button:hover,
a.button:hover {
  background-color: var(--c-tertiary);
  border: solid 1px var(--c-tertiary);
  color: var(--c-foreground);
}

.heading {
  position: relative;
  display: inline-block;
}

.heading .text-wrap {
  display: block;
}

.heading.hide-true {
  position: absolute;
  width: 0px;
  height: 0px;
  overflow: hidden;
  opacity: 0;
  padding: none !important;
}

.heading.blockEmphasis::before {
  content: '';
  width: calc(100% + ((100vw - var(--max-container-width)) / 2));
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -1;
}

.heading.padding-null {
  padding-top: var(--spacing-null);
  padding-bottom: var(--spacing-null);
  padding-right: var(--spacing-null);
}

.heading.padding-xs {
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
  padding-right: var(--spacing-xs);
}

.heading.padding-sm {
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-sm);
  padding-right: var(--spacing-sm);
}

.heading.padding-md {
  padding-top: var(--spacing-md);
  padding-bottom: var(--spacing-md);
  padding-right: var(--spacing-md);
}

.heading.padding-lg {
  padding-top: var(--spacing-lg);
  padding-bottom: var(--spacing-lg);
  padding-right: var(--spacing-lg);
}

.heading.padding-xl {
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
  padding-right: var(--spacing-xl);
}

.heading.padding-super {
  padding-top: var(--spacing-super);
  padding-bottom: var(--spacing-super);
  padding-right: var(--spacing-super);
}

.heading.blockEmphasis-background::before {
  background-color: var(--c-background);
}

.heading.align-left.lineEmphasis-background .text-wrap {
  padding-left: var(--grid-gutter-x);
  border-left: solid 4px var(--c-background);
}

.heading.align-right {
  text-align: right;
}

.heading.align-right.lineEmphasis-background {
  padding-right: var(--grid-gutter-x);
  border-right: solid 4px var(--c-background);
}

.heading.align-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 730px;
}

.heading.align-center.lineEmphasis-background {
  padding-bottom: var(--spacing-xs);
}

.heading.align-center.lineEmphasis-background::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--c-background);
  bottom: 0px;
  left: 0%;
}

.heading.blockEmphasis-foreground::before {
  background-color: var(--c-foreground);
}

.heading.align-left.lineEmphasis-foreground .text-wrap {
  padding-left: var(--grid-gutter-x);
  border-left: solid 4px var(--c-foreground);
}

.heading.align-right {
  text-align: right;
}

.heading.align-right.lineEmphasis-foreground {
  padding-right: var(--grid-gutter-x);
  border-right: solid 4px var(--c-foreground);
}

.heading.align-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 730px;
}

.heading.align-center.lineEmphasis-foreground {
  padding-bottom: var(--spacing-xs);
}

.heading.align-center.lineEmphasis-foreground::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--c-foreground);
  bottom: 0px;
  left: 0%;
}

.heading.blockEmphasis-primary::before {
  background-color: var(--c-primary);
}

.heading.align-left.lineEmphasis-primary .text-wrap {
  padding-left: var(--grid-gutter-x);
  border-left: solid 4px var(--c-primary);
}

.heading.align-right {
  text-align: right;
}

.heading.align-right.lineEmphasis-primary {
  padding-right: var(--grid-gutter-x);
  border-right: solid 4px var(--c-primary);
}

.heading.align-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 730px;
}

.heading.align-center.lineEmphasis-primary {
  padding-bottom: var(--spacing-xs);
}

.heading.align-center.lineEmphasis-primary::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--c-primary);
  bottom: 0px;
  left: 0%;
}

.heading.blockEmphasis-secondary::before {
  background-color: var(--c-secondary);
}

.heading.align-left.lineEmphasis-secondary .text-wrap {
  padding-left: var(--grid-gutter-x);
  border-left: solid 4px var(--c-secondary);
}

.heading.align-right {
  text-align: right;
}

.heading.align-right.lineEmphasis-secondary {
  padding-right: var(--grid-gutter-x);
  border-right: solid 4px var(--c-secondary);
}

.heading.align-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 730px;
}

.heading.align-center.lineEmphasis-secondary {
  padding-bottom: var(--spacing-xs);
}

.heading.align-center.lineEmphasis-secondary::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--c-secondary);
  bottom: 0px;
  left: 0%;
}

.heading.blockEmphasis-tertiary::before {
  background-color: var(--c-tertiary);
}

.heading.align-left.lineEmphasis-tertiary .text-wrap {
  padding-left: var(--grid-gutter-x);
  border-left: solid 4px var(--c-tertiary);
}

.heading.align-right {
  text-align: right;
}

.heading.align-right.lineEmphasis-tertiary {
  padding-right: var(--grid-gutter-x);
  border-right: solid 4px var(--c-tertiary);
}

.heading.align-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 730px;
}

.heading.align-center.lineEmphasis-tertiary {
  padding-bottom: var(--spacing-xs);
}

.heading.align-center.lineEmphasis-tertiary::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--c-tertiary);
  bottom: 0px;
  left: 0%;
}

.heading.blockEmphasis-quarternary::before {
  background-color: var(--c-quarternary);
}

.heading.align-left.lineEmphasis-quarternary .text-wrap {
  padding-left: var(--grid-gutter-x);
  border-left: solid 4px var(--c-quarternary);
}

.heading.align-right {
  text-align: right;
}

.heading.align-right.lineEmphasis-quarternary {
  padding-right: var(--grid-gutter-x);
  border-right: solid 4px var(--c-quarternary);
}

.heading.align-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 730px;
}

.heading.align-center.lineEmphasis-quarternary {
  padding-bottom: var(--spacing-xs);
}

.heading.align-center.lineEmphasis-quarternary::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--c-quarternary);
  bottom: 0px;
  left: 0%;
}

.heading.blockEmphasis-quinary::before {
  background-color: var(--c-quinary);
}

.heading.align-left.lineEmphasis-quinary .text-wrap {
  padding-left: var(--grid-gutter-x);
  border-left: solid 4px var(--c-quinary);
}

.heading.align-right {
  text-align: right;
}

.heading.align-right.lineEmphasis-quinary {
  padding-right: var(--grid-gutter-x);
  border-right: solid 4px var(--c-quinary);
}

.heading.align-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 730px;
}

.heading.align-center.lineEmphasis-quinary {
  padding-bottom: var(--spacing-xs);
}

.heading.align-center.lineEmphasis-quinary::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--c-quinary);
  bottom: 0px;
  left: 0%;
}

form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

form label {
  display: block;
  flex-basis: 100%;
  color: var(--c-background);
  font-size: var(--font-size-xs);
  line-height: var(--font-lineheight-xs);
  text-transform: uppercase;
  margin-bottom: var(--spacing-xs);
}

form div[role="group"]:not(.mce-*) {
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  border-bottom: solid 1px var(--c-background);
  padding-bottom: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
}

form input {
  display: block;
  flex-grow: 1;
  background: none;
  border: none;
  font-family: var(--font-family-body);
  color: var(--c-background);
  font-size: var(--font-size-body);
  line-height: var(--font-lineheight-body);
}

@media (min-width: 1200px) {
  header#header-mobile {
    display: none;
  }
}

header#header-mobile {
  position: fixed;
  width: 100vw;
  z-index: 10;
  top: 0;
  background-color: var(--c-foreground);
}

header#header-mobile li a,
header#header-mobile span a,
header#header-mobile a.quad {
  color: var(--c-background);
  font-size: var(--font-size-md);
  line-height: var(--font-lineheight-md);
  text-decoration: none;
  padding-bottom: var(--spacing-xs);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header#header-mobile a.quad {
  margin-top: auto;
}

header#header-mobile button {
  padding: 0px;
  -webkit-appearance: none;
  background: none;
  border: none;
}

header#header-mobile .search-page {
  width: 100%;
}

header#header-mobile .search-page .grid {
  display: flex !important;
  flex-wrap: wrap;
}

header#header-mobile .mobile-top-nav {
  height: calc(var(--spacing-md) * 2);
  display: flex;
  justify-content: space-between;
}

header#header-mobile .mobile-top-nav .search-button {
  height: 100%;
  width: calc(var(--spacing-md) * 2);
}

header#header-mobile .mobile-top-nav .search-toggle,
header#header-mobile .mobile-top-nav .menu-toggle {
  height: 100%;
  width: calc(var(--spacing-md) * 2);
}

header#header-mobile .mobile-top-nav .search-toggle.active-true,
header#header-mobile .mobile-top-nav .search-toggle.open-true,
header#header-mobile .mobile-top-nav .menu-toggle.active-true,
header#header-mobile .mobile-top-nav .menu-toggle.open-true {
  background-color: var(--c-primary);
}

header#header-mobile .mobile-top-nav .site-logo {
  height: 100%;
  max-width: 163px;
  display: flex;
}

header#header-mobile .mobile-top-nav .site-logo>img {
  -o-object-fit: contain;
     object-fit: contain;
}

header#header-mobile .mobile-top-nav .site-logo svg {
  width: 100px;
}

header#header-mobile #language-toggle {
  display: flex;
  width: 100%;
  margin-bottom: var(--spacing-sm);
}

header#header-mobile #language-toggle button {
  flex-basis: 50%;
  color: var(--c-background);
  font-size: var(--font-size-xs);
  line-height: var(--font-lineheight-md);
  font-family: var(--font-family-body);
  font-weight: var(--font-weight-semi);
  padding: var(--spacing-xs);
  border: solid 1px var(--c-senary);
}

header#header-mobile #language-toggle button.active {
  background-color: var(--c-senary);
}

header#header-mobile .menu-contents {
  display: flex;
  flex-direction: column;
}

header#header-mobile .menu-contents>* {
  max-width: calc(100vw - var(--spacing-lg));
  margin-left: auto;
  margin-right: auto;
}

header#header-mobile .menu-contents.open {
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-sm);
  height: calc(100vh - 80px);
}

header#header-mobile #nav-menu-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

header#header-mobile #nav-menu-wrapper>nav {
  margin-bottom: var(--spacing-md);
}

header#header-mobile #nav-menu-wrapper .prev-menu {
  display: flex;
  margin-bottom: var(--spacing-md);
}

header#header-mobile #nav-menu-wrapper .prev-menu svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

@-webkit-keyframes bubble-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(80);
            transform: scale(80);
  }
}

@keyframes bubble-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(80);
            transform: scale(80);
  }
}

@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes bubble-out {
  0% {
    -webkit-transform: scale(80);
            transform: scale(80);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes bubble-out {
  0% {
    -webkit-transform: scale(80);
            transform: scale(80);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.menu-icon {
  max-height: 30px;
  width: auto;
}

@media (min-width: 1200px) {
  body::before {
    content: '';
    position: absolute;
    background-color: var(--c-primary);
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 50%;
    top: 90px;
    right: calc((100vw - var(--max-container-width)) / 2);
    z-index: 2;
    opacity: 0;
  }

  body .search-page .divider,
  body .search-page .search-form label,
  body .search-page .search-form div[role="group"] {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }

  body.search-false::before {
    -webkit-animation-name: bubble-out;
            animation-name: bubble-out;
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }

  body.search-false .search-page .divider,
  body.search-false .search-page .search-form label,
  body.search-false .search-page .search-form div[role="group"] {
    -webkit-animation-name: fadeout;
            animation-name: fadeout;
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }

  body.search-true .main-menu-container>.site-logo,
  body.search-true .main-menu-container>nav {
    -webkit-animation-name: fadeout;
            animation-name: fadeout;
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }

  body.search-true::before {
    -webkit-animation-name: bubble-in;
            animation-name: bubble-in;
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }

  body.search-true .search-page .divider,
  body.search-true .search-page .search-form label,
  body.search-true .search-page .search-form div[role="group"] {
    -webkit-animation-name: stagger;
            animation-name: stagger;
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }

  body.search-true .search-page .divider,
  body.search-true .search-page .search-form label {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  body.search-true .search-page .search-form div[role="group"] {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }
}

header#header-desktop {
  display: none;
}

header#header-desktop::after {
  content: '';
  position: absolute;
  top: 0px;
  width: 100vw;
  height: 20vh;
  background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0));
}

header#header-desktop .navigate-top span {
  display: flex !important;
}

header#header-desktop .search-page {
  position: absolute;
  top: 0px;
  left: calc((100vw - var(--max-container-width)) / -2);
  width: 100vw;
  height: 100vh;
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
  overflow: scroll;
}

header#header-desktop .search-page .divider {
  grid-column: 2 / span 1;
  width: 1px;
  height: 100%;
  background-color: var(--c-secondary);
}

header#header-desktop .search-page .search-results {
  grid-column: 3 / span 6;
}

header#header-desktop .search-page form {
  grid-column: 3 / span 5;
}

header#header-desktop .search-page form input[type="text"]::-webkit-input-placeholder {
  color: var(--c-background);
  opacity: 0.6;
}

header#header-desktop .search-page form input[type="text"]::-moz-placeholder {
  color: var(--c-background);
  opacity: 0.6;
}

header#header-desktop .search-page form input[type="text"]::-ms-input-placeholder {
  color: var(--c-background);
  opacity: 0.6;
}

header#header-desktop .search-page form input[type="text"]::placeholder {
  color: var(--c-background);
  opacity: 0.6;
}

header#header-desktop .search-page form button {
  background: none;
  border: none;
}

header#header-desktop .eyebrow-container {
  position: fixed;
  top: 0px;
  z-index: 3;
  width: 100%;
  height: var(--spacing-lg);
  background-color: var(--c-foreground);
  display: flex;
  justify-content: flex-end;
}

header#header-desktop .eyebrow-container .menu-items {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

header#header-desktop .eyebrow-container ul {
  height: 100%;
  display: flex;
  align-items: center;
}

header#header-desktop .eyebrow-container li {
  list-style-type: none;
}

header#header-desktop .eyebrow-container a {
  display: flex;
  align-items: center;
  color: var(--c-background);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-lineheight-md);
  text-decoration: none;
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xs);
}

header#header-desktop .eyebrow-container .quad-logo {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 100%;
  display: flex;
  align-items: center;
}

header#header-desktop nav#language-toggle {
  display: flex;
}

header#header-desktop nav#language-toggle button {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  font-weight: var(--font-weight-bold);
  padding-left: var(--spacing-md);
  padding-right: var(--spacing-md);
  color: var(--c-background);
  background-color: var(--c-senary);
  font-family: var(--font-family-body);
  font-size: var(--font-size-xs);
  line-height: var(--font-lineheight-md);
  text-decoration: none;
}

header#header-desktop nav#language-toggle button:not(.active) {
  display: none;
}

header#header-desktop .main-menu-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 2;
  width: 100%;
  max-width: var(--max-container-width);
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

header#header-desktop .main-menu-container nav {
  flex-grow: 1;
  padding-left: var(--spacing-md);
  padding-top: var(--spacing-md);
}

header#header-desktop .main-menu-container ul {
  display: flex;
}

header#header-desktop .main-menu-container ul li {
  list-style-type: none;
  position: relative;
}

header#header-desktop .main-menu-container ul li:hover nav {
  display: block;
}

header#header-desktop .main-menu-container ul li nav {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding-left: 0;
  padding-top: 0;
  min-width: 200px;
}

header#header-desktop .main-menu-container ul li nav ul {
  display: flex;
  flex-direction: column;
  background-color: black;
}

header#header-desktop .main-menu-container nav a {
  font-size: var(--font-size-body);
  line-height: var(--font-lineheight-body);
  color: var(--c-background);
  padding: var(--spacing-xs);
  display: block;
  text-decoration: none;
  position: relative;
}

header#header-desktop .main-menu-container nav a::after {
  content: '';
  position: absolute;
  display: block;
  width: calc(100% - var(--spacing-md));
  height: 2px;
  background-color: var(--c-tertiary);
  bottom: 0px;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

header#header-desktop .main-menu-container nav a:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

header#header-desktop .main-menu-container nav a.has-children svg {
  display: none;
}

header#header-desktop .main-menu-container a.site-logo {
  max-width: 163px;
  padding: var(--spacing-xs);
}

header#header-desktop .main-menu-container a.site-logo svg {
  height: 60px;
}

header#header-desktop .main-menu-container a.site-logo>img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}

header#header-desktop .main-menu-container span {
  position: relative;
}

header#header-desktop .main-menu-container span nav {
  padding-top: 0;
  padding-left: 0;
  position: absolute;
  display: none;
}

header#header-desktop .main-menu-container span:hover>nav {
  display: flex;
}

header#header-desktop .main-menu-container span nav a {
  white-space: nowrap;
}

header#header-desktop button.search-toggle {
  z-index: 2;
  background-color: var(--c-primary);
  border: none;
  padding: 0;
  margin: 0;
  margin-top: var(--spacing-md);
  width: var(--spacing-lg);
  height: var(--spacing-lg);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

header#header-desktop button.search-toggle.active-true {
  background: var(--c-foreground);
}

header#header-desktop button.search-toggle.active-true svg {
  width: 20px;
}

@media (min-width: 1200px) {
  header#header-desktop {
    display: block;
  }
}

@-webkit-keyframes scrollin {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scrollin {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navigate-top {
  text-decoration: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  font-size: var(--font-size-xs);
  font-family: var(--font-family-body);
  color: var(--c-background);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navigate-top span {
  display: block;
  border: solid 1px var(--c-secondary);
  border-radius: 17.1px;
  height: 34.2px;
  margin-left: var(--font-size-xs);
  padding: calc(var(--font-size-xs) / 2) var(--font-size-xs);
}

.navigate-top svg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.navigate-top.scrolled-down-false {
  opacity: 0;
}

.navigate-top.scrolled-down-true {
  -webkit-animation: scrollin;
          animation: scrollin;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

footer {
  background-color: var(--c-foreground);
  padding-top: var(--spacing-lg);
  padding: var(--spacing-lg) var(--spacing-sm);
}

footer .footer-contents-main>*,
footer .footer-contents-sub>* {
  grid-column: 2 / span 10;
}

footer form.subscribe-form {
  display: block;
}

footer form.subscribe-form button.button {
  margin-top: var(--spacing-xs);
}

footer form div[role=group] {
  display: -ms-flexbox;
  display: block;
  justify-content: space-between;
  border-bottom: 1px solid var(--c-background);
  padding-bottom: var(--spacing-xs);
}

footer form input {
  display: block;
  flex-grow: 1;
  background: none;
  border: none;
  font-family: var(--font-family-body);
  color: var(--c-background);
  font-size: var(--font-size-body);
  line-height: var(--font-lineheight-body);
}

footer .contact a {
  color: var(--c-primary);
}

footer .form-container,
footer .contact,
footer .social-container,
footer ul.additional-links,
footer .copyright,
footer .navigate-top,
footer .additional-link-pal,
footer .divider {
  margin-bottom: var(--spacing-md);
}

footer h5 {
  font-size: var(--font-size-md);
  margin-bottom: var(--spacing-xs);
}

footer .contact p:last-of-type {
  margin-bottom: var(--spacing-sm);
}

footer .site-logo {
  display: flex;
  flex-wrap: wrap;
}

footer .site-logo h6 {
  margin-bottom: var(--spacing-xs);
}

footer .site-logo .logo-container {
  flex-basis: 100%;
}

footer .socials {
  display: flex;
  margin-bottom: var(--spacing-md);
}

footer .socials a {
  margin-right: var(--spacing-sm);
}

footer .socials a:hover svg {
  fill: var(--c-secondary);
}

footer .divider {
  border-bottom: solid 1px var(--c-background);
}

footer .footer-contents-sub {
  align-items: center;
  justify-content: space-between;
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-sm);
}

footer .additional-links {
  display: flex;
}

footer .additional-links li {
  display: flex;
  align-items: center;
}

footer .additional-links li::after {
  content: '|';
  color: var(--c-background);
  display: block;
  margin-left: calc(var(--spacing-xs) / 2);
  margin-right: calc(var(--spacing-xs) / 2);
}

footer .additional-links li:last-child::after {
  display: none;
}

footer .additional-links a {
  color: var(--c-background);
}

footer .copyright {
  display: block;
  color: var(--c-background);
  font-size: var(--font-size-xs);
  line-height: var(--font-lineheight-xs);
}

@media (min-width: 700px) {
  footer .footer-contents-main .form-container {
    grid-column: 2 / span 6;
  }
}

@media (min-width: 1200px) {
  footer .footer-contents-sub {
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
  }

  footer .footer-contents-sub .divider {
    flex-basis: 100%;
  }

  footer .footer-contents-main .form-container {
    grid-column: auto / span 4;
  }

  footer .footer-contents-main .contact {
    grid-column: 7 / span 3;
  }

  footer .footer-contents-main .social-container {
    grid-column: 10 / span 3;
  }
}

.leasing .featured-image {
  height: 250px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.leasing .building-introduction .image,
.leasing .building-introduction .title,
.leasing .building-introduction .content,
.leasing .building-introduction .divider {
  grid-column: 1 / span 12;
}

.leasing .building-introduction .divider {
  border-bottom: solid 1px #d8d8d8;
}

.leasing .building-introduction .suite-number {
  text-decoration: underline;
}

.leasing .building-introduction .building-links {
  grid-column: 1 / span 12;
  display: flex;
  justify-content: space-between;
}

.leasing .building-introduction .building-links .building-share a {
  display: flex;
  align-items: center;
}

.leasing .building-introduction .building-links .building-share a svg {
  opacity: 0.5;
}

.leasing .building-introduction .building-links .building-share a:hover svg {
  opacity: 1;
}

.leasing .building-introduction .building-links .building-type,
.leasing .building-introduction .building-links .building-share {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leasing .building-introduction .building-links .building-type svg,
.leasing .building-introduction .building-links .building-share svg {
  margin-right: calc(var(--spacing-xs) / 2);
}

.leasing .building-introduction .building-links .building-type {
  display: flex;
  align-items: baseline;
}

.leasing .building-content>* {
  grid-column: 1 / span 12;
}

.leasing .leasing-accordion .header {
  cursor: pointer;
}

.leasing .leasing-accordion .header h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leasing .leasing-accordion .suite-name:hover {
  cursor: pointer;
  color: var(--c-primary);
}

.leasing .leasing-accordion .suite-intro {
  cursor: pointer;
}

.leasing .leasing-accordion .suite-intro>.container.show-details-false {
  display: flex;
  justify-content: space-between;
}

.leasing .leasing-accordion .suite-intro>.container.show-details-true {
  display: flex;
  justify-content: flex-start;
}

.leasing .leasing-accordion .suite-intro>.container.show-details-true .suite-name {
  margin-left: var(--spacing-xs);
}

.leasing .leasing-accordion ul.accordion-contents>li {
  list-style-type: none;
  border-top: solid 1px #d8d8d8;
  background-color: var(--c-quinary);
}

.leasing .leasing-accordion ul.accordion-contents>li:last-child {
  border-bottom: solid 1px #d8d8d8;
}

.leasing .leasing-accordion ul.accordion-contents>li:nth-child(2n) {
  background-color: var(--c-background);
}

.leasing .building-overview {
  display: grid;
  grid-template-columns: 1fr 1fr !important;
  grid-gap: 20px;
  margin: 0 auto;
  margin-top: 40px;
}

.leasing .building-overview:empty {
  margin-top: 0px;
}

@media (min-width: 768px) {
  .leasing .building-overview {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}

@media (min-width: 1000px) {
  .leasing .building-overview {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }
}

.leasing .building-overview .detail {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.leasing .building-overview .detail img {
  display: block;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-bottom: 20px;
}

.leasing .building-overview .detail .building-overview-detail {
  display: block;
  text-align: center;
  font-weight: bold;
}

.leasing .building-overview .detail .building-overview-detail-title {
  display: block;
  text-align: center;
  margin-bottom: 10px;
  font-size: 80%;
  font-weight: bold;
}

.leasing .tennantcost h4 {
  margin-top: 40px;
}

.leasing table.building-details-table {
  margin: 20px 0 40px;
  width: 100%;
  border-bottom: 2px solid black;
}

.leasing table.building-details-table tr {
  border-top: 1px solid lightgray;
}

.leasing table.building-details-table tr td {
  padding: 18px 0px;
  width: 50%;
  font-weight: bold;
}

.leasing table.building-details-table tr td:last-child {
  font-weight: normal;
  text-align: right;
}

@media (max-width: 767px) {
  .leasing .building-introduction .image {
    grid-column: 1 / span 2;
    height: 300px;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media (min-width: 768px) {
  .leasing .featured-image {
    height: 560px;
  }

  .leasing .building-introduction .image {
    grid-column: 1 / span 2;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

  .leasing .building-introduction .title {
    grid-column: 3 / span 5;
  }

  .leasing .building-introduction .content {
    grid-column: 9 / span 4;
    text-align: right;
  }

  .leasing .building-content>* {
    grid-column: 1 / span 7;
  }

  .leasing .leasing-accordion {
    max-width: var(--container);
    margin-left: auto;
    margin-right: auto;
  }

  .leasing .leasing-accordion .suite-intro {
    border-bottom: solid 1px #d8d8d8;
  }

  .leasing .leasing-accordion ul.accordion-contents>li:last-child {
    border-bottom: none;
  }
}

.slider .static-images {
  grid-row-gap: var(--grid-gutter-x);
}

.slider .static-images .image-container {
  cursor: pointer;
  grid-column: auto / 6;
}

@media (min-width: 500px) {
  .slider .static-images .image-container {
    grid-column: auto / span 3;
  }
}

@media (min-width: 768px) {
  .slider .static-images .image-container {
    grid-column: auto / span 2;
  }
}

.ReactModal__Body--open .ril__navButtonNext,
.ReactModal__Body--open .ril__navButtonPrev {
  background: none;
  padding: 0px;
  width: 50px;
  height: 50px;
  background-color: var(--c-foreground);
  background-image: url(/static/media/ArrowRight.496ec171.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.ReactModal__Body--open .ril__navButtonPrev {
  background-image: url(/static/media/ArrowLeft.55afb432.svg);
}

@-webkit-keyframes flip {
  0% {
    -webkit-transform: rotateY(0deg) translateZ(0) scale(1, 1);
            transform: rotateY(0deg) translateZ(0) scale(1, 1);
  }

  100% {
    -webkit-transform: rotateY(180deg) translateZ(0) scale(1, 1);
            transform: rotateY(180deg) translateZ(0) scale(1, 1);
  }
}

@keyframes flip {
  0% {
    -webkit-transform: rotateY(0deg) translateZ(0) scale(1, 1);
            transform: rotateY(0deg) translateZ(0) scale(1, 1);
  }

  100% {
    -webkit-transform: rotateY(180deg) translateZ(0) scale(1, 1);
            transform: rotateY(180deg) translateZ(0) scale(1, 1);
  }
}

@-webkit-keyframes flip-out {
  0% {
    -webkit-transform: rotateY(180deg) translateZ(0) scale(1, 1);
            transform: rotateY(180deg) translateZ(0) scale(1, 1);
  }

  100% {
    -webkit-transform: rotateY(0deg) translateZ(0) scale(1, 1);
            transform: rotateY(0deg) translateZ(0) scale(1, 1);
  }
}

@keyframes flip-out {
  0% {
    -webkit-transform: rotateY(180deg) translateZ(0) scale(1, 1);
            transform: rotateY(180deg) translateZ(0) scale(1, 1);
  }

  100% {
    -webkit-transform: rotateY(0deg) translateZ(0) scale(1, 1);
            transform: rotateY(0deg) translateZ(0) scale(1, 1);
  }
}

.flip-card-list {
  grid-row-gap: var(--grid-gutter-x) !important;
}

.flip-card-list article.flip-card {
  grid-column: auto / span 12;
  -webkit-perspective: 1500px;
          perspective: 1500px;
  cursor: pointer;
}

.flip-card-list .card-content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 100%;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: -webkit-transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
  transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
  transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1), -webkit-transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
}

.flip-card-list .card-front,
.flip-card-list .card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.flip-card-list .card-back {
  -webkit-animation: flip 0.3s forwards;
          animation: flip 0.3s forwards;
  box-shadow: 0 4px 18px 3px rgba(0,0,0,0.12),0 10px 14px 1px rgba(0,0,0,0.14),0 6px 6px -3px rgba(0,0,0,0.2);
  opacity: 0;
}

.flip-card-list .card-back .inner-content-wrapper {
  background-size: 135%;
  background-repeat: no-repeat;
  background-position: 50% 10%;
  padding: var(--spacing-md);
}

.flip-card-list .card-back .inner-content-wrapper .card-content {
  font-size: var(--font-size-xs);
  line-height: var(--font-lineheight-xs);
}

.flip-card-list .card-back .inner-content-wrapper::after {
  content: '';
  background-color: var(--c-foreground);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.8;
}

.flip-card-list .card-front {
  -webkit-animation: flip-out 0.3s forwards;
          animation: flip-out 0.3s forwards;
}

.flip-card-list .card-front img {
  max-width: 70px;
}

.flip-card-list .inner-content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-sm);
  text-align: center;
}

.flip-card-list .inner-content-wrapper h6 {
  line-height: var(--font-lineheight-md);
}

.flip-card-list article.flip-card.flipped-true .card-back {
  -webkit-animation: flip-out 0.3s forwards;
          animation: flip-out 0.3s forwards;
  opacity: 1;
}

.flip-card-list article.flip-card.flipped-true .card-front {
  -webkit-animation: flip 0.3s forwards;
          animation: flip 0.3s forwards;
}

.flip-card-list .inner-content-wrapper {
  -webkit-transform: translateZ(80px) scale(0.94);
          transform: translateZ(80px) scale(0.94);
}

@media (min-width: 567px) {
  .flip-card-list article.flip-card {
    grid-column: auto / span 6;
  }
}

@media (min-width: 768px) {
  .flip-card-list article.flip-card {
    grid-column: auto / span 4;
  }
}

@media (min-width: 1000px) {
  .flip-card-list article.flip-card {
    grid-column: auto / span 3;
  }
}

.premise-event-listing article.single-event {
  border-bottom: solid 2px var(--c-background);
  position: relative;
  cursor: pointer;
}

.premise-event-listing article.single-event .grid {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  grid-row-gap: var(--grid-gutter-x) !important;
}

.premise-event-listing article.single-event p {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.92px;
  color: var(--c-background);
}

.premise-event-listing article.single-event .date-wrapper {
  grid-column: 1 / span 10;
  padding-left: var(--spacing-sm);
  padding-top: var(--spacing-xs);
}

.premise-event-listing article.single-event .main-content-wrapper {
  grid-column: 1 / span 10;
  border-left: solid 2px var(--c-secondary);
  padding-left: var(--spacing-sm);
  padding-top: 0px;
}

.premise-event-listing article.single-event button {
  color: var(--c-background);
  display: flex;
  align-items: center;
  justify-content: center;
}

.premise-event-listing article.single-event button span.expansion {
  padding-left: 5px;
  display: block;
  max-width: 0px;
  transition: max-width 0.2s ease-in;
  overflow: hidden;
  white-space: nowrap;
}

.premise-event-listing article.single-event button:hover {
  color: var(--c-foreground);
}

.premise-event-listing article.single-event button:hover span.expansion {
  max-width: 100px;
}

@media (min-width: 768px) {
  .premise-event-listing article.single-event .grid {
    padding-top: var(--spacing-md);
  }

  .premise-event-listing article.single-event .date-wrapper {
    grid-column: 1 / span 2;
    padding-top: 0px;
  }

  .premise-event-listing article.single-event .main-content-wrapper {
    padding-top: 0px;
    grid-column: 3 / span 5;
  }

  .premise-event-listing article.single-event button {
    position: absolute;
    top: var(--spacing-md);
    right: var(--spacing-md);
  }
}

.news-listing article.single-newsitem {
  border-bottom: solid 2px var(--c-background);
  position: relative;
}

.news-listing article.single-newsitem .grid {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  grid-row-gap: var(--grid-gutter-x) !important;
}

.news-listing article.single-newsitem .date-wrapper {
  grid-column: 1 / span 10;
  padding-left: var(--spacing-sm);
  padding-top: var(--spacing-xs);
}

.news-listing article.single-newsitem .date-wrapper p {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.92px;
  text-transform: uppercase;
}

.news-listing article.single-newsitem .main-content-wrapper {
  grid-column: 1 / span 10;
  border-left: solid 2px var(--c-secondary);
  padding-left: var(--spacing-sm);
  padding-top: 0px;
}

.news-listing article.single-newsitem .main-content-wrapper .container {
  padding: 0px !important;
}

.news-listing article.single-newsitem button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-listing article.single-newsitem button span.expansion {
  padding-left: 5px;
  display: block;
  max-width: 0px;
  transition: max-width 0.2s ease-in;
  overflow: hidden;
  white-space: nowrap;
}

.news-listing article.single-newsitem button:hover {
  color: var(--c-foreground);
}

.news-listing article.single-newsitem button:hover span.expansion {
  max-width: 100px;
}

@media (min-width: 768px) {
  .news-listing article.single-newsitem .grid {
    padding-top: var(--spacing-md);
  }

  .news-listing article.single-newsitem .date-wrapper {
    grid-column: 1 / span 2;
    padding-top: 0px;
  }

  .news-listing article.single-newsitem .main-content-wrapper {
    padding-top: 0px;
    grid-column: 3 / span 10;
  }

  .news-listing article.single-newsitem button {
    position: absolute;
    top: var(--spacing-md);
    right: var(--spacing-md);
  }
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

em {
  font-style: italic;
}

.editor-modal {
  border: solid 1px red;
  position: fixed;
  top: 20px;
  width: 200px;
  height: 200px;
  z-index: 10;
  background-color: white;
}

.editor-modal label,
.editor-modal input {
  color: blue;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body {
  min-height: 100vh;
  display: flex;
}

body #root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

body footer {
  margin-top: auto;
}

body.search-true {
  overflow: hidden;
}

img {
  max-width: 100%;
}

.grid {
  display: grid;
  width: 100%;
  max-width: var(--max-container-width);
  grid-column-gap: var(--grid-gutter-x);
  grid-row-gap: var(--grid-gutter-y);
  grid-template-columns: repeat(var(--layout-columns), 1fr);
  grid-auto-columns: var(--layout-columns) fr;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.grid .box {
  display: flex;
  flex-wrap: wrap;
}

.grid .box>* {
  align-self: flex-start;
}

.building-content.container .container {
  padding-left: 0px;
  padding-right: 0px;
}

.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.main-menu-container nav nav nav {
  display: none !important;
}

.tox.tox-silver-sink.tox-tinymce-aux {
  z-index: 100000;
}


.accordions {
  margin-bottom: var(--spacing-xl);
}

.accordion_single {
  border-bottom: 1px solid #d8d8d8;
  margin: 0 auto;
  padding: 30px;
  position: relative;
}

.accordion_single .accordion_single_arrow {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: var(--spacing-sm);
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
}

.accordion_single .accordion_single_arrow.open-true {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.accordion_single .accordion_title {
  font-size: 20px;
  color: var(--c-primary);
  width: calc(100% - 50px);
  cursor: pointer;
}

.accordion_single .accordion_title:hover {
  color: var(--c-quarternary);
}

.accordion_single .accordion_content {
  max-height: 0px;
  overflow: hidden;
}

.accordion_single .accordion_content.open-true {
  max-height: 10000px;
}

.buttonlinks {
  display: flex;
  flex-direction: row-reverse;
}

.button_links_inner {
  display: grid;
  width: 100%;
  grid-row-gap: 30px;
}

@media screen and (min-width: 767px) {
  .button_links_inner {
    grid-template-columns: calc((100% - 30px) / 2) calc((100% - 30px) / 2);
    grid-column-gap: 30px;
    padding: 20px;
  }
}

@media screen and (min-width: 1000px) {
  .button_links_inner {
    grid-template-columns: calc((100% - 60px) / 4) calc((100% - 60px) / 4) calc((100% - 60px) / 4) calc((100% - 60px) / 4);
  }
}

.button_links_inner h2 {
  color: var(--c-primary);
  font-family: var(--font-family-body);
  font-size: var(--font-size-lg);
}

.button_links_inner .buttonlink {
  display: flex;
  align-items: center;
  padding: var(--spacing-sm);
  background-color: var(--c-quinary);
  border: solid 3px #ffffff;
  font-size: 12px;
  line-height: 2;
  text-decoration: none;
  color: var(--c-foreground);
}

.button_links_inner .buttonlink img {
  max-width: 26px;
}

.button_links_inner .buttonlink:hover {
  border: solid 3px var(--c-primary);
  box-shadow: 0 4px 18px 3px rgba(0,0,0,0.12),0 10px 14px 1px rgba(0,0,0,0.14),0 6px 6px -3px rgba(0,0,0,0.2);
}

.button_links_inner .buttonlink .buttonlinktitle {
  margin-left: var(--spacing-sm);
}

.contentswitcher_control {
  margin: 0;
  padding: 0;
  list-style: none;
}

.contentswitcher_control li {
  cursor: pointer;
  font-size: 11px;
  text-transform: uppercase;
  padding-left: 30px;
  border-left: 2px solid transparent;
  cursor: pointer;
  margin-bottom: 20px;
}

.contentswitcher_control li.current-true {
  border-left: 2px solid var(--c-tertiary);
}

.contentswitcher_control li:hover {
  color: var(--c-primary);
}

.contentswitcher_item {
  display: none;
}

.contentswitcher_item.current-true {
  display: block;
}

.contentswitcher_item .contentswitcher_item_title {
  font-size: var(--font-size-lg);
  color: var(--c-primary);
  font-family: var(--font-family-body);
  font-weight: var(--font-weight-light);
  margin-bottom: var(--spacing-sm);
}

.contentswitcher_item .contentswitcher_item_content>p,
.contentswitcher_item .contentswitcher_item_content>h1,
.contentswitcher_item .contentswitcher_item_content>h2,
.contentswitcher_item .contentswitcher_item_content>h3,
.contentswitcher_item .contentswitcher_item_content>h4,
.contentswitcher_item .contentswitcher_item_content>h5,
.contentswitcher_item .contentswitcher_item_content>h6,
.contentswitcher_item .contentswitcher_item_content>ul,
.contentswitcher_item .contentswitcher_item_content>li,
.contentswitcher_item .contentswitcher_item_content>table {
  margin-bottom: var(--spacing-xs);
}

.contentswitcher_container {
  display: grid;
  grid-gap: var(--spacing-sm);
  grid-template-columns: 30% auto;
  margin: var(--spacing-sm) 0;
}

.directory_type_switcher {
  display: flex;
  list-style: none;
  justify-content: center;
}

.directory_type_switcher li {
  padding: var(--spacing-xs) calc(var(--spacing-sm) / 2);
  border-bottom: 2px solid transparent;
  cursor: pointer;
  white-space: nowrap;
}

.directory_type_switcher li.current-true {
  border-bottom: 2px solid var(--c-tertiary);
}

.directory_store {
  border-bottom: 2px solid var(--c-quinary);
  background-color: rgba(0,0,0,0.03);
  grid-row-gap: var(--grid-gutter-x) !important;
  position: relative;
  grid-template-columns: repeat(10, 1fr) !important;
  cursor: pointer;
}

.directory_store::after {
  content: '';
  width: 2px;
  height: 30%;
  background-color: var(--c-secondary);
  position: absolute;
  left: 0px;
  top: var(--spacing-sm);
}

.directory_store p {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.92px;
  color: #949494;
}

.directory_store .hours {
  max-width: 80%;
  max-height: 0;
  transition: max-height 0.3s ease-in;
  overflow: hidden;
}

.directory_store .hours .day {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.directory_store .hours .day p:first-child {
  padding-right: var(--spacing-xs);
}

.directory_store .hours .day p:last-child {
  text-align: right;
}

.directory_store:hover .hours {
  max-height: 200px;
}

.directory_store .store_content,
.directory_store .store_image {
  grid-column: 2 / span 8;
}

.directory_store .store_image {
  grid-row: -1;
}

.directory_store h3 {
  font-size: var(--font-size-md);
  font-family: var(--font-family-body);
  color: var(--c-primary);
  font-weight: var(--font-weight-light);
}

@media screen and (min-width: 768px) {
  .directory_store .store_content {
    grid-column: 2 / span 5;
    padding: var(--spacing-sm);
    margin-bottom: 0px;
  }

  .directory_store .store_image {
    grid-column: 7 / span 5;
    grid-row: unset;
    position: relative;
  }

  .directory_store .store_image.Office {
    grid-column: 9 / span 2;
  }

  .directory_store .store_image img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center right;
       object-position: center right;
  }
}

.directorymap {
  max-width: var(--inner-container);
  margin: 0 auto;
}

.pillbox {
  background: var(--c-secondary);
  display: inline-block;
  color: var(--c-senary);
  padding: 8px 18px 4px;
  font-size: var(--font-size-xs);
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: var(--spacing-xs);
  border-radius: 14px;
}

.directory_filters {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.directory_filters .directory_search input {
  padding: var(--spacing-xs) 0;
  border: none;
  border-bottom: 2px solid var(--c-tertiary);
}

@media screen and (max-width: 700px) {
  .directory_filters .directory_search {
    display: none;
  }
}

.currentTags {
  padding: var(--spacing-sm);
}

.currentTags .pillbox {
  margin-right: var(--spacing-sm);
  border: solid 1px var(--c-secondary);
  cursor: pointer;
}

.currentTags .pillbox.active-true {
  border: solid 1px var(--c-foreground);
}

@media screen and (max-width: 700px) {
  .currentTags {
    padding: var(--spacing-sm) 0;
  }
}

.modal .modal_content {
  padding: 0px;
  border: none;
}

.modal .modal_store .title-container {
  background-color: var(--c-primary);
  position: relative;
  overflow: hidden;
  padding: var(--spacing-md);
  padding-left: calc(var(--spacing-md) + var(--spacing-xs));
  padding-top: calc(var(--spacing-xl) * 2);
}

.modal .modal_store .title-container::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(to top, rgba(0,0,0,0.6), rgba(0,0,0,0));
}

.modal .modal_store .title-container img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.modal .modal_store .title-container h2 {
  margin-bottom: 0px;
  position: relative;
  z-index: 2;
}

.modal .pillbox {
  margin-bottom: var(--spacing-md);
  margin-right: var(--spacing-xs);
}

.modal .modal_store_content {
  padding: var(--spacing-md);
  padding-top: var(--spacing-xs);
}

.modal .modal_store_content .contact-info {
  position: relative;
  padding-left: var(--spacing-xs);
  text-transform: uppercase;
  color: #949494;
}

.modal .modal_store_content .contact-info::after {
  content: '';
  width: 2px;
  background-color: var(--c-secondary);
  position: absolute;
  height: 300%;
  left: 0px;
  bottom: 0px;
}

.modal .modal_store_content .modal_content .modal_close {
  top: 10px;
  right: 10px;
}

.modal {
  position: fixed;
  top: 0px;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255,255,255,0.7);
  z-index: 100;
  overflow: hidden;
}

.modal .modal_store {
  max-height: 80vh;
}

.modal_background {
  position: fixed;
  width: 100%;
  height: 100%;
}

.modal_content {
  position: relative;
  max-height: 90vh;
  width: calc(100% - 60px);
  max-width: var(--inner-container);
  margin: 0 auto;
  left: 0;
  right: 0;
  background: var(--c-background);
  border: solid 3px var(--c-secondary);
  box-shadow: 0 4px 18px 3px rgba(0,0,0,0.12),0 10px 14px 1px rgba(0,0,0,0.14),0 6px 6px -3px rgba(0,0,0,0.2);
  padding: var(--spacing-sm);
  top: 10vh;
  overflow: scroll;
}

.modal_content .modal_close {
  position: absolute;
  top: var(--spacing-xs);
  right: var(--spacing-xs);
  font-size: var(--font-size-lg);
  z-index: 1;
  cursor: pointer;
}

.modal_content .modal_close:hover {
  color: var(--c-primary);
}

.hero .slider {
  min-height: 70vh !important;
  overflow: hidden;
}

.hero .slider .slider-slide {
  width: 100% !important;
}

.hero .slider .slider-slide h1,
.hero .slider .slider-slide h2,
.hero .slider .slider-slide h3,
.hero .slider .slider-slide h4,
.hero .slider .slider-slide h5,
.hero .slider .slider-slide h6,
.hero .slider .slider-slide p,
.hero .slider .slider-slide a {
  opacity: 1;
}

.hero .slider .slider-slide h1:nth-child(1),
.hero .slider .slider-slide h2:nth-child(1),
.hero .slider .slider-slide h3:nth-child(1),
.hero .slider .slider-slide h4:nth-child(1),
.hero .slider .slider-slide h5:nth-child(1),
.hero .slider .slider-slide h6:nth-child(1),
.hero .slider .slider-slide p:nth-child(1),
.hero .slider .slider-slide a:nth-child(1) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(2),
.hero .slider .slider-slide h2:nth-child(2),
.hero .slider .slider-slide h3:nth-child(2),
.hero .slider .slider-slide h4:nth-child(2),
.hero .slider .slider-slide h5:nth-child(2),
.hero .slider .slider-slide h6:nth-child(2),
.hero .slider .slider-slide p:nth-child(2),
.hero .slider .slider-slide a:nth-child(2) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(3),
.hero .slider .slider-slide h2:nth-child(3),
.hero .slider .slider-slide h3:nth-child(3),
.hero .slider .slider-slide h4:nth-child(3),
.hero .slider .slider-slide h5:nth-child(3),
.hero .slider .slider-slide h6:nth-child(3),
.hero .slider .slider-slide p:nth-child(3),
.hero .slider .slider-slide a:nth-child(3) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(4),
.hero .slider .slider-slide h2:nth-child(4),
.hero .slider .slider-slide h3:nth-child(4),
.hero .slider .slider-slide h4:nth-child(4),
.hero .slider .slider-slide h5:nth-child(4),
.hero .slider .slider-slide h6:nth-child(4),
.hero .slider .slider-slide p:nth-child(4),
.hero .slider .slider-slide a:nth-child(4) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(5),
.hero .slider .slider-slide h2:nth-child(5),
.hero .slider .slider-slide h3:nth-child(5),
.hero .slider .slider-slide h4:nth-child(5),
.hero .slider .slider-slide h5:nth-child(5),
.hero .slider .slider-slide h6:nth-child(5),
.hero .slider .slider-slide p:nth-child(5),
.hero .slider .slider-slide a:nth-child(5) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(6),
.hero .slider .slider-slide h2:nth-child(6),
.hero .slider .slider-slide h3:nth-child(6),
.hero .slider .slider-slide h4:nth-child(6),
.hero .slider .slider-slide h5:nth-child(6),
.hero .slider .slider-slide h6:nth-child(6),
.hero .slider .slider-slide p:nth-child(6),
.hero .slider .slider-slide a:nth-child(6) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(7),
.hero .slider .slider-slide h2:nth-child(7),
.hero .slider .slider-slide h3:nth-child(7),
.hero .slider .slider-slide h4:nth-child(7),
.hero .slider .slider-slide h5:nth-child(7),
.hero .slider .slider-slide h6:nth-child(7),
.hero .slider .slider-slide p:nth-child(7),
.hero .slider .slider-slide a:nth-child(7) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(8),
.hero .slider .slider-slide h2:nth-child(8),
.hero .slider .slider-slide h3:nth-child(8),
.hero .slider .slider-slide h4:nth-child(8),
.hero .slider .slider-slide h5:nth-child(8),
.hero .slider .slider-slide h6:nth-child(8),
.hero .slider .slider-slide p:nth-child(8),
.hero .slider .slider-slide a:nth-child(8) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(9),
.hero .slider .slider-slide h2:nth-child(9),
.hero .slider .slider-slide h3:nth-child(9),
.hero .slider .slider-slide h4:nth-child(9),
.hero .slider .slider-slide h5:nth-child(9),
.hero .slider .slider-slide h6:nth-child(9),
.hero .slider .slider-slide p:nth-child(9),
.hero .slider .slider-slide a:nth-child(9) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(10),
.hero .slider .slider-slide h2:nth-child(10),
.hero .slider .slider-slide h3:nth-child(10),
.hero .slider .slider-slide h4:nth-child(10),
.hero .slider .slider-slide h5:nth-child(10),
.hero .slider .slider-slide h6:nth-child(10),
.hero .slider .slider-slide p:nth-child(10),
.hero .slider .slider-slide a:nth-child(10) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(11),
.hero .slider .slider-slide h2:nth-child(11),
.hero .slider .slider-slide h3:nth-child(11),
.hero .slider .slider-slide h4:nth-child(11),
.hero .slider .slider-slide h5:nth-child(11),
.hero .slider .slider-slide h6:nth-child(11),
.hero .slider .slider-slide p:nth-child(11),
.hero .slider .slider-slide a:nth-child(11) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(12),
.hero .slider .slider-slide h2:nth-child(12),
.hero .slider .slider-slide h3:nth-child(12),
.hero .slider .slider-slide h4:nth-child(12),
.hero .slider .slider-slide h5:nth-child(12),
.hero .slider .slider-slide h6:nth-child(12),
.hero .slider .slider-slide p:nth-child(12),
.hero .slider .slider-slide a:nth-child(12) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(13),
.hero .slider .slider-slide h2:nth-child(13),
.hero .slider .slider-slide h3:nth-child(13),
.hero .slider .slider-slide h4:nth-child(13),
.hero .slider .slider-slide h5:nth-child(13),
.hero .slider .slider-slide h6:nth-child(13),
.hero .slider .slider-slide p:nth-child(13),
.hero .slider .slider-slide a:nth-child(13) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(14),
.hero .slider .slider-slide h2:nth-child(14),
.hero .slider .slider-slide h3:nth-child(14),
.hero .slider .slider-slide h4:nth-child(14),
.hero .slider .slider-slide h5:nth-child(14),
.hero .slider .slider-slide h6:nth-child(14),
.hero .slider .slider-slide p:nth-child(14),
.hero .slider .slider-slide a:nth-child(14) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(15),
.hero .slider .slider-slide h2:nth-child(15),
.hero .slider .slider-slide h3:nth-child(15),
.hero .slider .slider-slide h4:nth-child(15),
.hero .slider .slider-slide h5:nth-child(15),
.hero .slider .slider-slide h6:nth-child(15),
.hero .slider .slider-slide p:nth-child(15),
.hero .slider .slider-slide a:nth-child(15) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(16),
.hero .slider .slider-slide h2:nth-child(16),
.hero .slider .slider-slide h3:nth-child(16),
.hero .slider .slider-slide h4:nth-child(16),
.hero .slider .slider-slide h5:nth-child(16),
.hero .slider .slider-slide h6:nth-child(16),
.hero .slider .slider-slide p:nth-child(16),
.hero .slider .slider-slide a:nth-child(16) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(17),
.hero .slider .slider-slide h2:nth-child(17),
.hero .slider .slider-slide h3:nth-child(17),
.hero .slider .slider-slide h4:nth-child(17),
.hero .slider .slider-slide h5:nth-child(17),
.hero .slider .slider-slide h6:nth-child(17),
.hero .slider .slider-slide p:nth-child(17),
.hero .slider .slider-slide a:nth-child(17) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(18),
.hero .slider .slider-slide h2:nth-child(18),
.hero .slider .slider-slide h3:nth-child(18),
.hero .slider .slider-slide h4:nth-child(18),
.hero .slider .slider-slide h5:nth-child(18),
.hero .slider .slider-slide h6:nth-child(18),
.hero .slider .slider-slide p:nth-child(18),
.hero .slider .slider-slide a:nth-child(18) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(19),
.hero .slider .slider-slide h2:nth-child(19),
.hero .slider .slider-slide h3:nth-child(19),
.hero .slider .slider-slide h4:nth-child(19),
.hero .slider .slider-slide h5:nth-child(19),
.hero .slider .slider-slide h6:nth-child(19),
.hero .slider .slider-slide p:nth-child(19),
.hero .slider .slider-slide a:nth-child(19) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(20),
.hero .slider .slider-slide h2:nth-child(20),
.hero .slider .slider-slide h3:nth-child(20),
.hero .slider .slider-slide h4:nth-child(20),
.hero .slider .slider-slide h5:nth-child(20),
.hero .slider .slider-slide h6:nth-child(20),
.hero .slider .slider-slide p:nth-child(20),
.hero .slider .slider-slide a:nth-child(20) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(21),
.hero .slider .slider-slide h2:nth-child(21),
.hero .slider .slider-slide h3:nth-child(21),
.hero .slider .slider-slide h4:nth-child(21),
.hero .slider .slider-slide h5:nth-child(21),
.hero .slider .slider-slide h6:nth-child(21),
.hero .slider .slider-slide p:nth-child(21),
.hero .slider .slider-slide a:nth-child(21) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(22),
.hero .slider .slider-slide h2:nth-child(22),
.hero .slider .slider-slide h3:nth-child(22),
.hero .slider .slider-slide h4:nth-child(22),
.hero .slider .slider-slide h5:nth-child(22),
.hero .slider .slider-slide h6:nth-child(22),
.hero .slider .slider-slide p:nth-child(22),
.hero .slider .slider-slide a:nth-child(22) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(23),
.hero .slider .slider-slide h2:nth-child(23),
.hero .slider .slider-slide h3:nth-child(23),
.hero .slider .slider-slide h4:nth-child(23),
.hero .slider .slider-slide h5:nth-child(23),
.hero .slider .slider-slide h6:nth-child(23),
.hero .slider .slider-slide p:nth-child(23),
.hero .slider .slider-slide a:nth-child(23) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(24),
.hero .slider .slider-slide h2:nth-child(24),
.hero .slider .slider-slide h3:nth-child(24),
.hero .slider .slider-slide h4:nth-child(24),
.hero .slider .slider-slide h5:nth-child(24),
.hero .slider .slider-slide h6:nth-child(24),
.hero .slider .slider-slide p:nth-child(24),
.hero .slider .slider-slide a:nth-child(24) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(25),
.hero .slider .slider-slide h2:nth-child(25),
.hero .slider .slider-slide h3:nth-child(25),
.hero .slider .slider-slide h4:nth-child(25),
.hero .slider .slider-slide h5:nth-child(25),
.hero .slider .slider-slide h6:nth-child(25),
.hero .slider .slider-slide p:nth-child(25),
.hero .slider .slider-slide a:nth-child(25) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(26),
.hero .slider .slider-slide h2:nth-child(26),
.hero .slider .slider-slide h3:nth-child(26),
.hero .slider .slider-slide h4:nth-child(26),
.hero .slider .slider-slide h5:nth-child(26),
.hero .slider .slider-slide h6:nth-child(26),
.hero .slider .slider-slide p:nth-child(26),
.hero .slider .slider-slide a:nth-child(26) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(27),
.hero .slider .slider-slide h2:nth-child(27),
.hero .slider .slider-slide h3:nth-child(27),
.hero .slider .slider-slide h4:nth-child(27),
.hero .slider .slider-slide h5:nth-child(27),
.hero .slider .slider-slide h6:nth-child(27),
.hero .slider .slider-slide p:nth-child(27),
.hero .slider .slider-slide a:nth-child(27) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(28),
.hero .slider .slider-slide h2:nth-child(28),
.hero .slider .slider-slide h3:nth-child(28),
.hero .slider .slider-slide h4:nth-child(28),
.hero .slider .slider-slide h5:nth-child(28),
.hero .slider .slider-slide h6:nth-child(28),
.hero .slider .slider-slide p:nth-child(28),
.hero .slider .slider-slide a:nth-child(28) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(29),
.hero .slider .slider-slide h2:nth-child(29),
.hero .slider .slider-slide h3:nth-child(29),
.hero .slider .slider-slide h4:nth-child(29),
.hero .slider .slider-slide h5:nth-child(29),
.hero .slider .slider-slide h6:nth-child(29),
.hero .slider .slider-slide p:nth-child(29),
.hero .slider .slider-slide a:nth-child(29) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(30),
.hero .slider .slider-slide h2:nth-child(30),
.hero .slider .slider-slide h3:nth-child(30),
.hero .slider .slider-slide h4:nth-child(30),
.hero .slider .slider-slide h5:nth-child(30),
.hero .slider .slider-slide h6:nth-child(30),
.hero .slider .slider-slide p:nth-child(30),
.hero .slider .slider-slide a:nth-child(30) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(31),
.hero .slider .slider-slide h2:nth-child(31),
.hero .slider .slider-slide h3:nth-child(31),
.hero .slider .slider-slide h4:nth-child(31),
.hero .slider .slider-slide h5:nth-child(31),
.hero .slider .slider-slide h6:nth-child(31),
.hero .slider .slider-slide p:nth-child(31),
.hero .slider .slider-slide a:nth-child(31) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(32),
.hero .slider .slider-slide h2:nth-child(32),
.hero .slider .slider-slide h3:nth-child(32),
.hero .slider .slider-slide h4:nth-child(32),
.hero .slider .slider-slide h5:nth-child(32),
.hero .slider .slider-slide h6:nth-child(32),
.hero .slider .slider-slide p:nth-child(32),
.hero .slider .slider-slide a:nth-child(32) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(33),
.hero .slider .slider-slide h2:nth-child(33),
.hero .slider .slider-slide h3:nth-child(33),
.hero .slider .slider-slide h4:nth-child(33),
.hero .slider .slider-slide h5:nth-child(33),
.hero .slider .slider-slide h6:nth-child(33),
.hero .slider .slider-slide p:nth-child(33),
.hero .slider .slider-slide a:nth-child(33) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(34),
.hero .slider .slider-slide h2:nth-child(34),
.hero .slider .slider-slide h3:nth-child(34),
.hero .slider .slider-slide h4:nth-child(34),
.hero .slider .slider-slide h5:nth-child(34),
.hero .slider .slider-slide h6:nth-child(34),
.hero .slider .slider-slide p:nth-child(34),
.hero .slider .slider-slide a:nth-child(34) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(35),
.hero .slider .slider-slide h2:nth-child(35),
.hero .slider .slider-slide h3:nth-child(35),
.hero .slider .slider-slide h4:nth-child(35),
.hero .slider .slider-slide h5:nth-child(35),
.hero .slider .slider-slide h6:nth-child(35),
.hero .slider .slider-slide p:nth-child(35),
.hero .slider .slider-slide a:nth-child(35) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(36),
.hero .slider .slider-slide h2:nth-child(36),
.hero .slider .slider-slide h3:nth-child(36),
.hero .slider .slider-slide h4:nth-child(36),
.hero .slider .slider-slide h5:nth-child(36),
.hero .slider .slider-slide h6:nth-child(36),
.hero .slider .slider-slide p:nth-child(36),
.hero .slider .slider-slide a:nth-child(36) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(37),
.hero .slider .slider-slide h2:nth-child(37),
.hero .slider .slider-slide h3:nth-child(37),
.hero .slider .slider-slide h4:nth-child(37),
.hero .slider .slider-slide h5:nth-child(37),
.hero .slider .slider-slide h6:nth-child(37),
.hero .slider .slider-slide p:nth-child(37),
.hero .slider .slider-slide a:nth-child(37) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(38),
.hero .slider .slider-slide h2:nth-child(38),
.hero .slider .slider-slide h3:nth-child(38),
.hero .slider .slider-slide h4:nth-child(38),
.hero .slider .slider-slide h5:nth-child(38),
.hero .slider .slider-slide h6:nth-child(38),
.hero .slider .slider-slide p:nth-child(38),
.hero .slider .slider-slide a:nth-child(38) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(39),
.hero .slider .slider-slide h2:nth-child(39),
.hero .slider .slider-slide h3:nth-child(39),
.hero .slider .slider-slide h4:nth-child(39),
.hero .slider .slider-slide h5:nth-child(39),
.hero .slider .slider-slide h6:nth-child(39),
.hero .slider .slider-slide p:nth-child(39),
.hero .slider .slider-slide a:nth-child(39) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(40),
.hero .slider .slider-slide h2:nth-child(40),
.hero .slider .slider-slide h3:nth-child(40),
.hero .slider .slider-slide h4:nth-child(40),
.hero .slider .slider-slide h5:nth-child(40),
.hero .slider .slider-slide h6:nth-child(40),
.hero .slider .slider-slide p:nth-child(40),
.hero .slider .slider-slide a:nth-child(40) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(41),
.hero .slider .slider-slide h2:nth-child(41),
.hero .slider .slider-slide h3:nth-child(41),
.hero .slider .slider-slide h4:nth-child(41),
.hero .slider .slider-slide h5:nth-child(41),
.hero .slider .slider-slide h6:nth-child(41),
.hero .slider .slider-slide p:nth-child(41),
.hero .slider .slider-slide a:nth-child(41) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(42),
.hero .slider .slider-slide h2:nth-child(42),
.hero .slider .slider-slide h3:nth-child(42),
.hero .slider .slider-slide h4:nth-child(42),
.hero .slider .slider-slide h5:nth-child(42),
.hero .slider .slider-slide h6:nth-child(42),
.hero .slider .slider-slide p:nth-child(42),
.hero .slider .slider-slide a:nth-child(42) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(43),
.hero .slider .slider-slide h2:nth-child(43),
.hero .slider .slider-slide h3:nth-child(43),
.hero .slider .slider-slide h4:nth-child(43),
.hero .slider .slider-slide h5:nth-child(43),
.hero .slider .slider-slide h6:nth-child(43),
.hero .slider .slider-slide p:nth-child(43),
.hero .slider .slider-slide a:nth-child(43) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(44),
.hero .slider .slider-slide h2:nth-child(44),
.hero .slider .slider-slide h3:nth-child(44),
.hero .slider .slider-slide h4:nth-child(44),
.hero .slider .slider-slide h5:nth-child(44),
.hero .slider .slider-slide h6:nth-child(44),
.hero .slider .slider-slide p:nth-child(44),
.hero .slider .slider-slide a:nth-child(44) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(45),
.hero .slider .slider-slide h2:nth-child(45),
.hero .slider .slider-slide h3:nth-child(45),
.hero .slider .slider-slide h4:nth-child(45),
.hero .slider .slider-slide h5:nth-child(45),
.hero .slider .slider-slide h6:nth-child(45),
.hero .slider .slider-slide p:nth-child(45),
.hero .slider .slider-slide a:nth-child(45) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(46),
.hero .slider .slider-slide h2:nth-child(46),
.hero .slider .slider-slide h3:nth-child(46),
.hero .slider .slider-slide h4:nth-child(46),
.hero .slider .slider-slide h5:nth-child(46),
.hero .slider .slider-slide h6:nth-child(46),
.hero .slider .slider-slide p:nth-child(46),
.hero .slider .slider-slide a:nth-child(46) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(47),
.hero .slider .slider-slide h2:nth-child(47),
.hero .slider .slider-slide h3:nth-child(47),
.hero .slider .slider-slide h4:nth-child(47),
.hero .slider .slider-slide h5:nth-child(47),
.hero .slider .slider-slide h6:nth-child(47),
.hero .slider .slider-slide p:nth-child(47),
.hero .slider .slider-slide a:nth-child(47) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(48),
.hero .slider .slider-slide h2:nth-child(48),
.hero .slider .slider-slide h3:nth-child(48),
.hero .slider .slider-slide h4:nth-child(48),
.hero .slider .slider-slide h5:nth-child(48),
.hero .slider .slider-slide h6:nth-child(48),
.hero .slider .slider-slide p:nth-child(48),
.hero .slider .slider-slide a:nth-child(48) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(49),
.hero .slider .slider-slide h2:nth-child(49),
.hero .slider .slider-slide h3:nth-child(49),
.hero .slider .slider-slide h4:nth-child(49),
.hero .slider .slider-slide h5:nth-child(49),
.hero .slider .slider-slide h6:nth-child(49),
.hero .slider .slider-slide p:nth-child(49),
.hero .slider .slider-slide a:nth-child(49) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(50),
.hero .slider .slider-slide h2:nth-child(50),
.hero .slider .slider-slide h3:nth-child(50),
.hero .slider .slider-slide h4:nth-child(50),
.hero .slider .slider-slide h5:nth-child(50),
.hero .slider .slider-slide h6:nth-child(50),
.hero .slider .slider-slide p:nth-child(50),
.hero .slider .slider-slide a:nth-child(50) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(51),
.hero .slider .slider-slide h2:nth-child(51),
.hero .slider .slider-slide h3:nth-child(51),
.hero .slider .slider-slide h4:nth-child(51),
.hero .slider .slider-slide h5:nth-child(51),
.hero .slider .slider-slide h6:nth-child(51),
.hero .slider .slider-slide p:nth-child(51),
.hero .slider .slider-slide a:nth-child(51) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(52),
.hero .slider .slider-slide h2:nth-child(52),
.hero .slider .slider-slide h3:nth-child(52),
.hero .slider .slider-slide h4:nth-child(52),
.hero .slider .slider-slide h5:nth-child(52),
.hero .slider .slider-slide h6:nth-child(52),
.hero .slider .slider-slide p:nth-child(52),
.hero .slider .slider-slide a:nth-child(52) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(53),
.hero .slider .slider-slide h2:nth-child(53),
.hero .slider .slider-slide h3:nth-child(53),
.hero .slider .slider-slide h4:nth-child(53),
.hero .slider .slider-slide h5:nth-child(53),
.hero .slider .slider-slide h6:nth-child(53),
.hero .slider .slider-slide p:nth-child(53),
.hero .slider .slider-slide a:nth-child(53) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(54),
.hero .slider .slider-slide h2:nth-child(54),
.hero .slider .slider-slide h3:nth-child(54),
.hero .slider .slider-slide h4:nth-child(54),
.hero .slider .slider-slide h5:nth-child(54),
.hero .slider .slider-slide h6:nth-child(54),
.hero .slider .slider-slide p:nth-child(54),
.hero .slider .slider-slide a:nth-child(54) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(55),
.hero .slider .slider-slide h2:nth-child(55),
.hero .slider .slider-slide h3:nth-child(55),
.hero .slider .slider-slide h4:nth-child(55),
.hero .slider .slider-slide h5:nth-child(55),
.hero .slider .slider-slide h6:nth-child(55),
.hero .slider .slider-slide p:nth-child(55),
.hero .slider .slider-slide a:nth-child(55) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(56),
.hero .slider .slider-slide h2:nth-child(56),
.hero .slider .slider-slide h3:nth-child(56),
.hero .slider .slider-slide h4:nth-child(56),
.hero .slider .slider-slide h5:nth-child(56),
.hero .slider .slider-slide h6:nth-child(56),
.hero .slider .slider-slide p:nth-child(56),
.hero .slider .slider-slide a:nth-child(56) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(57),
.hero .slider .slider-slide h2:nth-child(57),
.hero .slider .slider-slide h3:nth-child(57),
.hero .slider .slider-slide h4:nth-child(57),
.hero .slider .slider-slide h5:nth-child(57),
.hero .slider .slider-slide h6:nth-child(57),
.hero .slider .slider-slide p:nth-child(57),
.hero .slider .slider-slide a:nth-child(57) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(58),
.hero .slider .slider-slide h2:nth-child(58),
.hero .slider .slider-slide h3:nth-child(58),
.hero .slider .slider-slide h4:nth-child(58),
.hero .slider .slider-slide h5:nth-child(58),
.hero .slider .slider-slide h6:nth-child(58),
.hero .slider .slider-slide p:nth-child(58),
.hero .slider .slider-slide a:nth-child(58) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(59),
.hero .slider .slider-slide h2:nth-child(59),
.hero .slider .slider-slide h3:nth-child(59),
.hero .slider .slider-slide h4:nth-child(59),
.hero .slider .slider-slide h5:nth-child(59),
.hero .slider .slider-slide h6:nth-child(59),
.hero .slider .slider-slide p:nth-child(59),
.hero .slider .slider-slide a:nth-child(59) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(60),
.hero .slider .slider-slide h2:nth-child(60),
.hero .slider .slider-slide h3:nth-child(60),
.hero .slider .slider-slide h4:nth-child(60),
.hero .slider .slider-slide h5:nth-child(60),
.hero .slider .slider-slide h6:nth-child(60),
.hero .slider .slider-slide p:nth-child(60),
.hero .slider .slider-slide a:nth-child(60) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(61),
.hero .slider .slider-slide h2:nth-child(61),
.hero .slider .slider-slide h3:nth-child(61),
.hero .slider .slider-slide h4:nth-child(61),
.hero .slider .slider-slide h5:nth-child(61),
.hero .slider .slider-slide h6:nth-child(61),
.hero .slider .slider-slide p:nth-child(61),
.hero .slider .slider-slide a:nth-child(61) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(62),
.hero .slider .slider-slide h2:nth-child(62),
.hero .slider .slider-slide h3:nth-child(62),
.hero .slider .slider-slide h4:nth-child(62),
.hero .slider .slider-slide h5:nth-child(62),
.hero .slider .slider-slide h6:nth-child(62),
.hero .slider .slider-slide p:nth-child(62),
.hero .slider .slider-slide a:nth-child(62) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(63),
.hero .slider .slider-slide h2:nth-child(63),
.hero .slider .slider-slide h3:nth-child(63),
.hero .slider .slider-slide h4:nth-child(63),
.hero .slider .slider-slide h5:nth-child(63),
.hero .slider .slider-slide h6:nth-child(63),
.hero .slider .slider-slide p:nth-child(63),
.hero .slider .slider-slide a:nth-child(63) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(64),
.hero .slider .slider-slide h2:nth-child(64),
.hero .slider .slider-slide h3:nth-child(64),
.hero .slider .slider-slide h4:nth-child(64),
.hero .slider .slider-slide h5:nth-child(64),
.hero .slider .slider-slide h6:nth-child(64),
.hero .slider .slider-slide p:nth-child(64),
.hero .slider .slider-slide a:nth-child(64) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(65),
.hero .slider .slider-slide h2:nth-child(65),
.hero .slider .slider-slide h3:nth-child(65),
.hero .slider .slider-slide h4:nth-child(65),
.hero .slider .slider-slide h5:nth-child(65),
.hero .slider .slider-slide h6:nth-child(65),
.hero .slider .slider-slide p:nth-child(65),
.hero .slider .slider-slide a:nth-child(65) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(66),
.hero .slider .slider-slide h2:nth-child(66),
.hero .slider .slider-slide h3:nth-child(66),
.hero .slider .slider-slide h4:nth-child(66),
.hero .slider .slider-slide h5:nth-child(66),
.hero .slider .slider-slide h6:nth-child(66),
.hero .slider .slider-slide p:nth-child(66),
.hero .slider .slider-slide a:nth-child(66) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(67),
.hero .slider .slider-slide h2:nth-child(67),
.hero .slider .slider-slide h3:nth-child(67),
.hero .slider .slider-slide h4:nth-child(67),
.hero .slider .slider-slide h5:nth-child(67),
.hero .slider .slider-slide h6:nth-child(67),
.hero .slider .slider-slide p:nth-child(67),
.hero .slider .slider-slide a:nth-child(67) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(68),
.hero .slider .slider-slide h2:nth-child(68),
.hero .slider .slider-slide h3:nth-child(68),
.hero .slider .slider-slide h4:nth-child(68),
.hero .slider .slider-slide h5:nth-child(68),
.hero .slider .slider-slide h6:nth-child(68),
.hero .slider .slider-slide p:nth-child(68),
.hero .slider .slider-slide a:nth-child(68) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(69),
.hero .slider .slider-slide h2:nth-child(69),
.hero .slider .slider-slide h3:nth-child(69),
.hero .slider .slider-slide h4:nth-child(69),
.hero .slider .slider-slide h5:nth-child(69),
.hero .slider .slider-slide h6:nth-child(69),
.hero .slider .slider-slide p:nth-child(69),
.hero .slider .slider-slide a:nth-child(69) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(70),
.hero .slider .slider-slide h2:nth-child(70),
.hero .slider .slider-slide h3:nth-child(70),
.hero .slider .slider-slide h4:nth-child(70),
.hero .slider .slider-slide h5:nth-child(70),
.hero .slider .slider-slide h6:nth-child(70),
.hero .slider .slider-slide p:nth-child(70),
.hero .slider .slider-slide a:nth-child(70) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(71),
.hero .slider .slider-slide h2:nth-child(71),
.hero .slider .slider-slide h3:nth-child(71),
.hero .slider .slider-slide h4:nth-child(71),
.hero .slider .slider-slide h5:nth-child(71),
.hero .slider .slider-slide h6:nth-child(71),
.hero .slider .slider-slide p:nth-child(71),
.hero .slider .slider-slide a:nth-child(71) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(72),
.hero .slider .slider-slide h2:nth-child(72),
.hero .slider .slider-slide h3:nth-child(72),
.hero .slider .slider-slide h4:nth-child(72),
.hero .slider .slider-slide h5:nth-child(72),
.hero .slider .slider-slide h6:nth-child(72),
.hero .slider .slider-slide p:nth-child(72),
.hero .slider .slider-slide a:nth-child(72) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(73),
.hero .slider .slider-slide h2:nth-child(73),
.hero .slider .slider-slide h3:nth-child(73),
.hero .slider .slider-slide h4:nth-child(73),
.hero .slider .slider-slide h5:nth-child(73),
.hero .slider .slider-slide h6:nth-child(73),
.hero .slider .slider-slide p:nth-child(73),
.hero .slider .slider-slide a:nth-child(73) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(74),
.hero .slider .slider-slide h2:nth-child(74),
.hero .slider .slider-slide h3:nth-child(74),
.hero .slider .slider-slide h4:nth-child(74),
.hero .slider .slider-slide h5:nth-child(74),
.hero .slider .slider-slide h6:nth-child(74),
.hero .slider .slider-slide p:nth-child(74),
.hero .slider .slider-slide a:nth-child(74) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(75),
.hero .slider .slider-slide h2:nth-child(75),
.hero .slider .slider-slide h3:nth-child(75),
.hero .slider .slider-slide h4:nth-child(75),
.hero .slider .slider-slide h5:nth-child(75),
.hero .slider .slider-slide h6:nth-child(75),
.hero .slider .slider-slide p:nth-child(75),
.hero .slider .slider-slide a:nth-child(75) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(76),
.hero .slider .slider-slide h2:nth-child(76),
.hero .slider .slider-slide h3:nth-child(76),
.hero .slider .slider-slide h4:nth-child(76),
.hero .slider .slider-slide h5:nth-child(76),
.hero .slider .slider-slide h6:nth-child(76),
.hero .slider .slider-slide p:nth-child(76),
.hero .slider .slider-slide a:nth-child(76) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(77),
.hero .slider .slider-slide h2:nth-child(77),
.hero .slider .slider-slide h3:nth-child(77),
.hero .slider .slider-slide h4:nth-child(77),
.hero .slider .slider-slide h5:nth-child(77),
.hero .slider .slider-slide h6:nth-child(77),
.hero .slider .slider-slide p:nth-child(77),
.hero .slider .slider-slide a:nth-child(77) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(78),
.hero .slider .slider-slide h2:nth-child(78),
.hero .slider .slider-slide h3:nth-child(78),
.hero .slider .slider-slide h4:nth-child(78),
.hero .slider .slider-slide h5:nth-child(78),
.hero .slider .slider-slide h6:nth-child(78),
.hero .slider .slider-slide p:nth-child(78),
.hero .slider .slider-slide a:nth-child(78) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(79),
.hero .slider .slider-slide h2:nth-child(79),
.hero .slider .slider-slide h3:nth-child(79),
.hero .slider .slider-slide h4:nth-child(79),
.hero .slider .slider-slide h5:nth-child(79),
.hero .slider .slider-slide h6:nth-child(79),
.hero .slider .slider-slide p:nth-child(79),
.hero .slider .slider-slide a:nth-child(79) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(80),
.hero .slider .slider-slide h2:nth-child(80),
.hero .slider .slider-slide h3:nth-child(80),
.hero .slider .slider-slide h4:nth-child(80),
.hero .slider .slider-slide h5:nth-child(80),
.hero .slider .slider-slide h6:nth-child(80),
.hero .slider .slider-slide p:nth-child(80),
.hero .slider .slider-slide a:nth-child(80) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(81),
.hero .slider .slider-slide h2:nth-child(81),
.hero .slider .slider-slide h3:nth-child(81),
.hero .slider .slider-slide h4:nth-child(81),
.hero .slider .slider-slide h5:nth-child(81),
.hero .slider .slider-slide h6:nth-child(81),
.hero .slider .slider-slide p:nth-child(81),
.hero .slider .slider-slide a:nth-child(81) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(82),
.hero .slider .slider-slide h2:nth-child(82),
.hero .slider .slider-slide h3:nth-child(82),
.hero .slider .slider-slide h4:nth-child(82),
.hero .slider .slider-slide h5:nth-child(82),
.hero .slider .slider-slide h6:nth-child(82),
.hero .slider .slider-slide p:nth-child(82),
.hero .slider .slider-slide a:nth-child(82) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(83),
.hero .slider .slider-slide h2:nth-child(83),
.hero .slider .slider-slide h3:nth-child(83),
.hero .slider .slider-slide h4:nth-child(83),
.hero .slider .slider-slide h5:nth-child(83),
.hero .slider .slider-slide h6:nth-child(83),
.hero .slider .slider-slide p:nth-child(83),
.hero .slider .slider-slide a:nth-child(83) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(84),
.hero .slider .slider-slide h2:nth-child(84),
.hero .slider .slider-slide h3:nth-child(84),
.hero .slider .slider-slide h4:nth-child(84),
.hero .slider .slider-slide h5:nth-child(84),
.hero .slider .slider-slide h6:nth-child(84),
.hero .slider .slider-slide p:nth-child(84),
.hero .slider .slider-slide a:nth-child(84) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(85),
.hero .slider .slider-slide h2:nth-child(85),
.hero .slider .slider-slide h3:nth-child(85),
.hero .slider .slider-slide h4:nth-child(85),
.hero .slider .slider-slide h5:nth-child(85),
.hero .slider .slider-slide h6:nth-child(85),
.hero .slider .slider-slide p:nth-child(85),
.hero .slider .slider-slide a:nth-child(85) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(86),
.hero .slider .slider-slide h2:nth-child(86),
.hero .slider .slider-slide h3:nth-child(86),
.hero .slider .slider-slide h4:nth-child(86),
.hero .slider .slider-slide h5:nth-child(86),
.hero .slider .slider-slide h6:nth-child(86),
.hero .slider .slider-slide p:nth-child(86),
.hero .slider .slider-slide a:nth-child(86) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(87),
.hero .slider .slider-slide h2:nth-child(87),
.hero .slider .slider-slide h3:nth-child(87),
.hero .slider .slider-slide h4:nth-child(87),
.hero .slider .slider-slide h5:nth-child(87),
.hero .slider .slider-slide h6:nth-child(87),
.hero .slider .slider-slide p:nth-child(87),
.hero .slider .slider-slide a:nth-child(87) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(88),
.hero .slider .slider-slide h2:nth-child(88),
.hero .slider .slider-slide h3:nth-child(88),
.hero .slider .slider-slide h4:nth-child(88),
.hero .slider .slider-slide h5:nth-child(88),
.hero .slider .slider-slide h6:nth-child(88),
.hero .slider .slider-slide p:nth-child(88),
.hero .slider .slider-slide a:nth-child(88) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(89),
.hero .slider .slider-slide h2:nth-child(89),
.hero .slider .slider-slide h3:nth-child(89),
.hero .slider .slider-slide h4:nth-child(89),
.hero .slider .slider-slide h5:nth-child(89),
.hero .slider .slider-slide h6:nth-child(89),
.hero .slider .slider-slide p:nth-child(89),
.hero .slider .slider-slide a:nth-child(89) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(90),
.hero .slider .slider-slide h2:nth-child(90),
.hero .slider .slider-slide h3:nth-child(90),
.hero .slider .slider-slide h4:nth-child(90),
.hero .slider .slider-slide h5:nth-child(90),
.hero .slider .slider-slide h6:nth-child(90),
.hero .slider .slider-slide p:nth-child(90),
.hero .slider .slider-slide a:nth-child(90) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(91),
.hero .slider .slider-slide h2:nth-child(91),
.hero .slider .slider-slide h3:nth-child(91),
.hero .slider .slider-slide h4:nth-child(91),
.hero .slider .slider-slide h5:nth-child(91),
.hero .slider .slider-slide h6:nth-child(91),
.hero .slider .slider-slide p:nth-child(91),
.hero .slider .slider-slide a:nth-child(91) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(92),
.hero .slider .slider-slide h2:nth-child(92),
.hero .slider .slider-slide h3:nth-child(92),
.hero .slider .slider-slide h4:nth-child(92),
.hero .slider .slider-slide h5:nth-child(92),
.hero .slider .slider-slide h6:nth-child(92),
.hero .slider .slider-slide p:nth-child(92),
.hero .slider .slider-slide a:nth-child(92) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(93),
.hero .slider .slider-slide h2:nth-child(93),
.hero .slider .slider-slide h3:nth-child(93),
.hero .slider .slider-slide h4:nth-child(93),
.hero .slider .slider-slide h5:nth-child(93),
.hero .slider .slider-slide h6:nth-child(93),
.hero .slider .slider-slide p:nth-child(93),
.hero .slider .slider-slide a:nth-child(93) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(94),
.hero .slider .slider-slide h2:nth-child(94),
.hero .slider .slider-slide h3:nth-child(94),
.hero .slider .slider-slide h4:nth-child(94),
.hero .slider .slider-slide h5:nth-child(94),
.hero .slider .slider-slide h6:nth-child(94),
.hero .slider .slider-slide p:nth-child(94),
.hero .slider .slider-slide a:nth-child(94) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(95),
.hero .slider .slider-slide h2:nth-child(95),
.hero .slider .slider-slide h3:nth-child(95),
.hero .slider .slider-slide h4:nth-child(95),
.hero .slider .slider-slide h5:nth-child(95),
.hero .slider .slider-slide h6:nth-child(95),
.hero .slider .slider-slide p:nth-child(95),
.hero .slider .slider-slide a:nth-child(95) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(96),
.hero .slider .slider-slide h2:nth-child(96),
.hero .slider .slider-slide h3:nth-child(96),
.hero .slider .slider-slide h4:nth-child(96),
.hero .slider .slider-slide h5:nth-child(96),
.hero .slider .slider-slide h6:nth-child(96),
.hero .slider .slider-slide p:nth-child(96),
.hero .slider .slider-slide a:nth-child(96) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(97),
.hero .slider .slider-slide h2:nth-child(97),
.hero .slider .slider-slide h3:nth-child(97),
.hero .slider .slider-slide h4:nth-child(97),
.hero .slider .slider-slide h5:nth-child(97),
.hero .slider .slider-slide h6:nth-child(97),
.hero .slider .slider-slide p:nth-child(97),
.hero .slider .slider-slide a:nth-child(97) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(98),
.hero .slider .slider-slide h2:nth-child(98),
.hero .slider .slider-slide h3:nth-child(98),
.hero .slider .slider-slide h4:nth-child(98),
.hero .slider .slider-slide h5:nth-child(98),
.hero .slider .slider-slide h6:nth-child(98),
.hero .slider .slider-slide p:nth-child(98),
.hero .slider .slider-slide a:nth-child(98) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(99),
.hero .slider .slider-slide h2:nth-child(99),
.hero .slider .slider-slide h3:nth-child(99),
.hero .slider .slider-slide h4:nth-child(99),
.hero .slider .slider-slide h5:nth-child(99),
.hero .slider .slider-slide h6:nth-child(99),
.hero .slider .slider-slide p:nth-child(99),
.hero .slider .slider-slide a:nth-child(99) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .slider .slider-slide h1:nth-child(100),
.hero .slider .slider-slide h2:nth-child(100),
.hero .slider .slider-slide h3:nth-child(100),
.hero .slider .slider-slide h4:nth-child(100),
.hero .slider .slider-slide h5:nth-child(100),
.hero .slider .slider-slide h6:nth-child(100),
.hero .slider .slider-slide p:nth-child(100),
.hero .slider .slider-slide a:nth-child(100) {
  -webkit-animation: unset;
          animation: unset;
  -webkit-animation-timing-function: unset;
          animation-timing-function: unset;
  -webkit-animation-duration: unset;
          animation-duration: unset;
  -webkit-animation-fill-mode: unset;
          animation-fill-mode: unset;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.hero .hero_slide {
  position: relative;
}

.hero .hero_slide .hero_slide_image {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.hero .hero_slide .hero_slide_image img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
}

.hero .hero_slide .hero_slide_container {
  position: relative;
  z-index: 1;
  color: var(--c-background);
}

@media screen and (max-width: 600px) {
  .hero .hero_slide .heading .text-wrap {
    font-size: 43px;
  }
}

.hero .hero_slide .hero_slide_title,
.hero .hero_slide .hero_slide_content {
  max-width: 522px;
}

.rich_text {
  width: 100%;
  margin: 0 auto;
}

.rich_text>p,
.rich_text>h1,
.rich_text>h2,
.rich_text>h3,
.rich_text>h4,
.rich_text>h5,
.rich_text>h6,
.rich_text>ul,
.rich_text>li,
.rich_text>table {
  margin-bottom: var(--spacing-xs);
}

.banner {
  position: relative;
  color: var(--c-background);
}

.banner.alignment-full {
  max-width: 100%;
}

.banner.alignment-full .inner-container {
  text-align: center;
  margin: 0 auto;
  max-width: 532px;
}

.banner .banner_separator {
  margin-top: var(--spacing-md);
}

.banner .banner_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.banner .banner_image video,
.banner .banner_image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.banner .inner-container {
  position: relative;
  z-index: 1;
}

.banner .object-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

@media screen and (min-width: 767px) {
  .banner {
    padding-left: var(--spacing-lg);
    padding-right: var(--spacing-lg);
  }
}

.contentmedia {
  padding-top: var(--spacing-md);
  padding-bottom: var(--spacing-md);
  margin-bottom: 0px;
}

.contentmedia.sp-m-null {
  margin-bottom: calc(var(--spacing-null) + var(--spacing-md)) !important;
}

.contentmedia.sp-m-xs {
  margin-bottom: calc(var(--spacing-xs) + var(--spacing-md)) !important;
}

.contentmedia.sp-m-sm {
  margin-bottom: calc(var(--spacing-sm) + var(--spacing-md)) !important;
}

.contentmedia.sp-m-md {
  margin-bottom: calc(var(--spacing-md) + var(--spacing-md)) !important;
}

.contentmedia.sp-m-lg {
  margin-bottom: calc(var(--spacing-lg) + var(--spacing-md)) !important;
}

.contentmedia.sp-m-xl {
  margin-bottom: calc(var(--spacing-xl) + var(--spacing-md)) !important;
}

.contentmedia.sp-m-super {
  margin-bottom: calc(var(--spacing-super) + var(--spacing-md)) !important;
}

.contentmedia.image-first-mobile-true .contentmedia_image,
.contentmedia.flip-true .contentmedia_image {
  margin-bottom: var(--spacing-md);
}

.contentmedia.image-first-mobile-true {
  display: flex;
  flex-direction: column-reverse;
}

.contentmedia.image-first-mobile-true .contentmedia_image:before {
  top: 35%;
}

.contentmedia .contentmedia_content .richtext>p,
.contentmedia .contentmedia_content .richtext>h1,
.contentmedia .contentmedia_content .richtext>h2,
.contentmedia .contentmedia_content .richtext>h3,
.contentmedia .contentmedia_content .richtext>h4,
.contentmedia .contentmedia_content .richtext>h5,
.contentmedia .contentmedia_content .richtext>h6,
.contentmedia .contentmedia_content .richtext>ul,
.contentmedia .contentmedia_content .richtext>li,
.contentmedia .contentmedia_content .richtext>table {
  margin-bottom: var(--spacing-xs);
}

.contentmedia .contentmedia_content {
  position: relative;
  z-index: 2;
}

.contentmedia .contentmedia_content h2 {
  font-family: var(--font-family-body);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-light);
  color: var(--c-primary);
}

.contentmedia .contentmedia_image {
  position: relative;
}

.contentmedia .contentmedia_image img,
.contentmedia .contentmedia_image video {
  position: relative;
  z-index: 1;
}

.contentmedia .contentmedia_image:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--c-quinary);
  top: -35%;
  left: -100px;
}

@media screen and (min-width: 992px) {
  .contentmedia,
  .contentmedia.image-first-mobile-true {
    display: grid;
    margin-bottom: var(--spacing-lg);
    grid-template-columns: 50% auto 30% auto;
  }

  .contentmedia.flip-true,
  .contentmedia.image-first-mobile-true.flip-true {
    grid-template-columns: 30% auto 50% auto;
  }

  .contentmedia.flip-true .contentmedia_image,
  .contentmedia.image-first-mobile-true.flip-true .contentmedia_image {
    grid-column-start: 3;
    grid-row-start: 1;
  }

  .contentmedia.flip-true .contentmedia_image:before,
  .contentmedia.image-first-mobile-true.flip-true .contentmedia_image:before {
    right: 70%;
  }

  .contentmedia.flip-true .contentmedia_content,
  .contentmedia.image-first-mobile-true.flip-true .contentmedia_content {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  .contentmedia .contentmedia_content,
  .contentmedia.image-first-mobile-true .contentmedia_content {
    grid-column-start: 3;
    grid-row-start: 1;
    position: sticky;
  }

  .contentmedia .contentmedia_image,
  .contentmedia.image-first-mobile-true .contentmedia_image {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  .contentmedia .contentmedia_image:before,
  .contentmedia.image-first-mobile-true .contentmedia_image:before {
    width: 130%;
    height: calc(100% + 80px + 80px);
    position: absolute;
    right: -100%;
    top: -80px;
    left: auto;
  }
}

.icontext {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.icontext .icontext_content {
  width: 100%;
}

.icontext figure.icontext_image {
  margin-bottom: var(--spacing-sm);
}

.icontext figure.icontext_image:empty {
  min-width: 200px;
  width: 200px;
  height: 200px;
  background-color: var(--c-quinary);
  margin-right: var(--grid-gutter-x);
}

.icontext figure.icontext_image img {
  width: 200px;
  min-width: 200px;
  margin-right: var(--grid-gutter-x);
}

.icontext strong {
  display: block;
  margin-bottom: var(--spacing-xs);
}

@media (min-width: 768px) {
  figure.icontext_image img {
    margin-bottom: var(--spacing-null);
  }

  .icontext {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

.pagetitle {
  position: relative;
  color: var(--c-background);
  padding-bottom: 0;
}

.pagetitle h1 {
  position: relative;
  z-index: 1;
  padding-bottom: var(--spacing-xl);
}

.pagetitle .pagetitle_crumbs {
  position: relative;
  z-index: 1;
  color: var(--c-foreground);
}

.pagetitle .pagetitle_crumbs li {
  display: inline;
  margin-right: 12px;
  font-size: var(--font-size-xs);
}

.pagetitle .pagetitle_crumbs li a {
  color: var(--c-foreground);
}

.pagetitle .pagetitle_crumbs li:after {
  content: "/";
  margin-left: 12px;
  display: inline-block;
}

.pagetitle .pagetitle_crumbs li:last-child:after {
  display: none;
}

.pagetitle .page_title_image {
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100% - 60px);
  background: var(--c-foreground);
}

.pagetitle .page_title_image img,
.pagetitle .page_title_image video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}


